import { LswOption } from '@ui/lsw-lib';
import { MbsProductHttp } from './mbsProduct';

export const CitiesMock: string[] = [
  'city A',
];

export const StreetsMock: LswOption[] = [
  {viewValue: 'Street Autocomp1', value: 'Street Autocomp1'},
  {viewValue: 'Street Autocomp2', value: 'Street Autocomp2'},
  {viewValue: 'Street Autocomp3', value: 'Street Autocomp3'},
];

export const MbsProductsStromMock: MbsProductHttp =
{
  isSufficientInformationProvided: true,
  priceInformation: [
    {
      tariff: {
        periodStart: null,
        periodEnd: null,
        id: 110,
        nameExtern: 'Brillant Strom Natur 12',
        name: 'Brillant Strom Natur 12',
        energyPriceNet: 0.2806,
        energyPriceTax: 0.053314,
        energyPriceGross: 0.333914,
        baseFeeNet: 11,
        baseFeeTax: 2.09,
        baseFeeGross: 13.09,
        boni: [{
          dueDate: null,
          nameExternal: 'Neukundenbonus',
          payback: null,
          state: null,
          type: 'valueBonus',
          value: '88,57',
          valueNet: '74,43',
        },
        {
          dueDate: null,
          nameExternal: 'Sofortbonus',
          payback: null,
          state: null,
          type: 'valueBonus',
          value: '217,40',
          valueNet: '182,69',
        }],
        cancellationTerm: 6,
        cancellationTermUnit: 'W',
        cancellationTermTo: 'T',
        priceGuarantyFor: 12,
        priceGuarantyEnd: null,
        runtimeInMonth: 12,
        renewalInMonth: 12,
        portalInfoAdditionalPriceDetails: null,
        portalInfoAdditionalTariffDescription: null,
        tariffType: null
      },
      priceCalculation: {
        advancePayPlanHeight: 75,
        advancePayPlanHeightNet: 63.03,
        totalCostPerMonth: 68.74,
        totalCostPerMonthNet: 57.76,
        totalCostPerYear: 824.91,
        totalCostPerYearNet: 693.2,
        totalCostPerMonthInFirstYear: 68.74,
        totalCostPerMonthInFirstYearNet: 57.76,
        totalCostPerYearInFirstYear: 824.91,
        totalCostPerYearInFirstYearNet: 693.2,
        totalSavingsPerYear: 0,
        totalSavingsPerYearNet: 0,
        regionalPricePeriodStart: 1577833200000
      },
      charges: {
        chargesEntries: [
          {
            key: 'base_price',
            name: 'Grundpreis',
            value: 11,
            unit: 'EURO_YEARLY',
            sum: 11,
            unitSum: 'EURO'
          },
          {
            key: 'energy_price',
            name: 'Arbeitspreis',
            value: 0.2806,
            unit: 'EURO_KWH',
            sum: 561.2,
            unitSum: 'EURO'
          },
          {
            key: 'grid_base_price',
            name: 'Grundpreis Netznutzung',
            value: 58.4,
            unit: 'EURO_YEARLY',
            sum: 58.4,
            unitSum: 'EURO'
          }
        ],
        totalChargesPerYear: 630.6,
        totalChargesPerYearUnit: 'EURO',
        totalChargesPerKwH: 28.06,
        totalChargesPerKwHUnit: 'CENT_KWH'
      },
      additionalCalculationData: [
        {
          tariffId: '110',
          peak: null,
          expectedPaymentPlanPerMonth: 77.72,
          expectedPaymentPlanPerMonthUnit: 'EURO',
          expectedPaymentPlanPerYear: 932.64,
          expectedPaymentPlanPerYearUnit: 'EURO',
          msbPriceOnKwh: 0.429,
          msbPriceOnKwhUnit: 'CENT',
          baseGridPriceOnKwh: 2.92,
          baseGridPriceOnKwhUnit: 'CENT',
          netPowerPriceOnKwh: 0,
          netPowerPriceOnKwhUnit: 'CENT',
          sumOfExpensesAndCharges: 18.02,
          sumOfExpensesAndChargesUnit: 'CENT',
          sumOfExpensesAndMSB: 15.1,
          sumOfExpensesAndMSBUnit: 'CENT',
          sumOfExpensesAndGridPricePerYear: 360.4,
          sumOfExpensesAndGridPricePerYearUnit: 'EURO'
        }
      ]
    },
    {
      tariff: {
        periodStart: null,
        periodEnd: null,
        id: 112,
        nameExtern: 'Brillant Strom Natur 24',
        name: 'Brillant Strom Natur 24',
        energyPriceNet: 0.2856,
        energyPriceTax: 0.054264,
        energyPriceGross: 0.339864,
        baseFeeNet: 11,
        baseFeeTax: 2.09,
        baseFeeGross: 13.09,
        boni: [{
          dueDate: null,
          nameExternal: 'Neukundenbonus',
          payback: null,
          state: null,
          type: 'valueBonus',
          value: '88,57',
          valueNet: '74,43',
        },
        {
          dueDate: null,
          nameExternal: 'Sofortbonus',
          payback: null,
          state: null,
          type: 'valueBonus',
          value: '217,40',
          valueNet: '182,69',
        }],
        cancellationTerm: 6,
        cancellationTermUnit: 'W',
        cancellationTermTo: 'T',
        priceGuarantyFor: 24,
        priceGuarantyEnd: null,
        runtimeInMonth: 24,
        renewalInMonth: 12,
        portalInfoAdditionalPriceDetails: null,
        portalInfoAdditionalTariffDescription: null,
        tariffType: null
      },
      priceCalculation: {
        advancePayPlanHeight: 77,
        advancePayPlanHeightNet: 64.71,
        totalCostPerMonth: 69.73,
        totalCostPerMonthNet: 58.6,
        totalCostPerYear: 836.81,
        totalCostPerYearNet: 703.2,
        totalCostPerMonthInFirstYear: 69.73,
        totalCostPerMonthInFirstYearNet: 58.6,
        totalCostPerYearInFirstYear: 836.81,
        totalCostPerYearInFirstYearNet: 703.2,
        totalSavingsPerYear: 0,
        totalSavingsPerYearNet: 0,
        regionalPricePeriodStart: 1577833200000
      },
      charges: {
        chargesEntries: [
          {
            key: 'base_price',
            name: 'Grundpreis',
            value: 11,
            unit: 'EURO_YEARLY',
            sum: 11,
            unitSum: 'EURO'
          },
          {
            key: 'energy_price',
            name: 'Arbeitspreis',
            value: 0.2856,
            unit: 'EURO_KWH',
            sum: 571.2,
            unitSum: 'EURO'
          },
          {
            key: 'grid_base_price',
            name: 'Grundpreis Netznutzung',
            value: 58.4,
            unit: 'EURO_YEARLY',
            sum: 58.4,
            unitSum: 'EURO'
          }
        ],
        totalChargesPerYear: 640.6,
        totalChargesPerYearUnit: 'EURO',
        totalChargesPerKwH: 28.56,
        totalChargesPerKwHUnit: 'CENT_KWH'
      },
      additionalCalculationData: [
        {
          tariffId: '112',
          peak: null,
          expectedPaymentPlanPerMonth: 78.55,
          expectedPaymentPlanPerMonthUnit: 'EURO',
          expectedPaymentPlanPerYear: 942.6,
          expectedPaymentPlanPerYearUnit: 'EURO',
          msbPriceOnKwh: 0.429,
          msbPriceOnKwhUnit: 'CENT',
          baseGridPriceOnKwh: 2.92,
          baseGridPriceOnKwhUnit: 'CENT',
          netPowerPriceOnKwh: 0,
          netPowerPriceOnKwhUnit: 'CENT',
          sumOfExpensesAndCharges: 18.02,
          sumOfExpensesAndChargesUnit: 'CENT',
          sumOfExpensesAndMSB: 15.1,
          sumOfExpensesAndMSBUnit: 'CENT',
          sumOfExpensesAndGridPricePerYear: 360.4,
          sumOfExpensesAndGridPricePerYearUnit: 'EURO'
        }
      ]
    }
  ],
  expenses: {
    expenseEntries: [
      {
        key: '100',
        name: 'Netznutzung',
        value: 0.0389,
        unit: 'EURO_KWH',
        sum: 77.8,
        unitSum: 'EURO'
      },
      {
        key: '101',
        name: 'Konzessionsabgabe',
        value: 0.0132,
        unit: 'EURO_KWH',
        sum: 26.4,
        unitSum: 'EURO'
      },
      {
        key: '102',
        name: 'Messdienstleistung',
        value: 0,
        unit: 'EURO_KWH',
        sum: 0,
        unitSum: 'EURO'
      },
      {
        key: '103',
        name: 'Messtellenbetrieb',
        value: 8.58,
        unit: 'EURO_YEARLY',
        sum: 8.58,
        unitSum: 'EURO'
      },
      {
        key: '104',
        name: '§ 19 StromNEV-Umlage',
        value: 0.00305,
        unit: 'EURO_KWH',
        sum: 6.1,
        unitSum: 'EURO'
      },
      {
        key: '105',
        name: 'KWKG-Umlage',
        value: 0.0028,
        unit: 'EURO_KWH',
        sum: 5.6,
        unitSum: 'EURO'
      },
      {
        key: '106',
        name: 'Offshore-Haftungsumlage',
        value: 0.00416,
        unit: 'EURO_KWH',
        sum: 8.32,
        unitSum: 'EURO'
      },
      {
        key: '107',
        name: 'abLa-Umlage',
        value: 0.00005,
        unit: 'EURO_KWH',
        sum: 0.1,
        unitSum: 'EURO'
      },
      {
        key: '300',
        name: 'EEG-Umlage',
        value: 0.06405,
        unit: 'EURO_KWH',
        sum: 128.1,
        unitSum: 'EURO'
      },
      {
        key: '301',
        name: 'Stromsteuer',
        value: 0.0205,
        unit: 'EURO_KWH',
        sum: 41,
        unitSum: 'EURO'
      }
    ],
    totalExpensesPerMonth: 25.166666667,
    totalExpensesPerMonthUnit: 'EURO',
    totalExpensesPerYear: 302,
    totalExpensesPerYearUnit: 'EURO',
    totalExpensesCostPerKwH: 14.671,
    totalExpensesCostsPerKwHUnit: 'CENT_KWH',
    totalExpensesCostPerYear: 8.58,
    totalExpensesCostsPerYearUnit: 'EURO_YEARLY'
  }
};

export const MbsProductsStromMockMulti: MbsProductHttp = {
  isSufficientInformationProvided: false,
  priceInformation: [
    {
      tariff: {
        periodStart: null,
        periodEnd: null,
        id: 110,
        nameExtern: 'Brillant Strom Natur 12',
        name: 'Brillant Strom Natur 12',
        energyPriceNet: 0.2806,
        energyPriceTax: 0.053314,
        energyPriceGross: 0.333914,
        baseFeeNet: 11,
        baseFeeTax: 2.09,
        baseFeeGross: 13.09,
        boni: [{
          dueDate: null,
          nameExternal: 'Neukundenbonus',
          payback: null,
          state: null,
          type: 'valueBonus',
          value: '88,57',
          valueNet: '74,43',
        },
        {
          dueDate: null,
          nameExternal: 'Sofortbonus',
          payback: null,
          state: null,
          type: 'valueBonus',
          value: '217,40',
          valueNet: '182,69',
        }],
        cancellationTerm: 6,
        cancellationTermUnit: 'W',
        cancellationTermTo: 'T',
        priceGuarantyFor: 12,
        priceGuarantyEnd: null,
        runtimeInMonth: 12,
        renewalInMonth: 12,
        portalInfoAdditionalPriceDetails: null,
        portalInfoAdditionalTariffDescription: null,
        tariffType: null
      },
      priceCalculation: {
        advancePayPlanHeight: 75,
        advancePayPlanHeightNet: 63.03,
        totalCostPerMonth: 68.74,
        totalCostPerMonthNet: 57.76,
        totalCostPerYear: 824.91,
        totalCostPerYearNet: 693.2,
        totalCostPerMonthInFirstYear: 68.74,
        totalCostPerMonthInFirstYearNet: 57.76,
        totalCostPerYearInFirstYear: 824.91,
        totalCostPerYearInFirstYearNet: 693.2,
        totalSavingsPerYear: 0,
        totalSavingsPerYearNet: 0,
        regionalPricePeriodStart: 1577833200000
      },
      charges: {
        chargesEntries: [
          {
            key: 'base_price',
            name: 'Grundpreis',
            value: 11,
            unit: 'EURO_YEARLY',
            sum: 11,
            unitSum: 'EURO'
          },
          {
            key: 'energy_price',
            name: 'Arbeitspreis',
            value: 0.2806,
            unit: 'EURO_KWH',
            sum: 561.2,
            unitSum: 'EURO'
          },
          {
            key: 'grid_base_price',
            name: 'Grundpreis Netznutzung',
            value: 58.4,
            unit: 'EURO_YEARLY',
            sum: 58.4,
            unitSum: 'EURO'
          }
        ],
        totalChargesPerYear: 630.6,
        totalChargesPerYearUnit: 'EURO',
        totalChargesPerKwH: 28.06,
        totalChargesPerKwHUnit: 'CENT_KWH'
      },
      additionalCalculationData: [
        {
          tariffId: '110',
          peak: null,
          expectedPaymentPlanPerMonth: 77.72,
          expectedPaymentPlanPerMonthUnit: 'EURO',
          expectedPaymentPlanPerYear: 932.64,
          expectedPaymentPlanPerYearUnit: 'EURO',
          msbPriceOnKwh: 0.429,
          msbPriceOnKwhUnit: 'CENT',
          baseGridPriceOnKwh: 2.92,
          baseGridPriceOnKwhUnit: 'CENT',
          netPowerPriceOnKwh: 0,
          netPowerPriceOnKwhUnit: 'CENT',
          sumOfExpensesAndCharges: 18.02,
          sumOfExpensesAndChargesUnit: 'CENT',
          sumOfExpensesAndMSB: 15.1,
          sumOfExpensesAndMSBUnit: 'CENT',
          sumOfExpensesAndGridPricePerYear: 360.4,
          sumOfExpensesAndGridPricePerYearUnit: 'EURO'
        }
      ]
    },
    {
      tariff: {
        periodStart: null,
        periodEnd: null,
        id: 112,
        nameExtern: 'Brillant Strom Natur 24',
        name: 'Brillant Strom Natur 24',
        energyPriceNet: 0.2856,
        energyPriceTax: 0.054264,
        energyPriceGross: 0.339864,
        baseFeeNet: 11,
        baseFeeTax: 2.09,
        baseFeeGross: 13.09,
        boni: [{
          dueDate: null,
          nameExternal: 'Neukundenbonus',
          payback: null,
          state: null,
          type: 'valueBonus',
          value: '88,57',
          valueNet: '74,43',
        },
        {
          dueDate: null,
          nameExternal: 'Sofortbonus',
          payback: null,
          state: null,
          type: 'valueBonus',
          value: '217,40',
          valueNet: '182,69',
        }],
        cancellationTerm: 6,
        cancellationTermUnit: 'W',
        cancellationTermTo: 'T',
        priceGuarantyFor: 24,
        priceGuarantyEnd: null,
        runtimeInMonth: 24,
        renewalInMonth: 12,
        portalInfoAdditionalPriceDetails: null,
        portalInfoAdditionalTariffDescription: null,
        tariffType: null
      },
      priceCalculation: {
        advancePayPlanHeight: 77,
        advancePayPlanHeightNet: 64.71,
        totalCostPerMonth: 69.73,
        totalCostPerMonthNet: 58.6,
        totalCostPerYear: 836.81,
        totalCostPerYearNet: 703.2,
        totalCostPerMonthInFirstYear: 69.73,
        totalCostPerMonthInFirstYearNet: 58.6,
        totalCostPerYearInFirstYear: 836.81,
        totalCostPerYearInFirstYearNet: 703.2,
        totalSavingsPerYear: 0,
        totalSavingsPerYearNet: 0,
        regionalPricePeriodStart: 1577833200000
      },
      charges: {
        chargesEntries: [
          {
            key: 'base_price',
            name: 'Grundpreis',
            value: 11,
            unit: 'EURO_YEARLY',
            sum: 11,
            unitSum: 'EURO'
          },
          {
            key: 'energy_price',
            name: 'Arbeitspreis',
            value: 0.2856,
            unit: 'EURO_KWH',
            sum: 571.2,
            unitSum: 'EURO'
          },
          {
            key: 'grid_base_price',
            name: 'Grundpreis Netznutzung',
            value: 58.4,
            unit: 'EURO_YEARLY',
            sum: 58.4,
            unitSum: 'EURO'
          }
        ],
        totalChargesPerYear: 640.6,
        totalChargesPerYearUnit: 'EURO',
        totalChargesPerKwH: 28.56,
        totalChargesPerKwHUnit: 'CENT_KWH'
      },
      additionalCalculationData: [
        {
          tariffId: '112',
          peak: null,
          expectedPaymentPlanPerMonth: 78.55,
          expectedPaymentPlanPerMonthUnit: 'EURO',
          expectedPaymentPlanPerYear: 942.6,
          expectedPaymentPlanPerYearUnit: 'EURO',
          msbPriceOnKwh: 0.429,
          msbPriceOnKwhUnit: 'CENT',
          baseGridPriceOnKwh: 2.92,
          baseGridPriceOnKwhUnit: 'CENT',
          netPowerPriceOnKwh: 0,
          netPowerPriceOnKwhUnit: 'CENT',
          sumOfExpensesAndCharges: 18.02,
          sumOfExpensesAndChargesUnit: 'CENT',
          sumOfExpensesAndMSB: 15.1,
          sumOfExpensesAndMSBUnit: 'CENT',
          sumOfExpensesAndGridPricePerYear: 360.4,
          sumOfExpensesAndGridPricePerYearUnit: 'EURO'
        }
      ]
    }
  ],
  expenses: {
    expenseEntries: [
      {
        key: '100',
        name: 'Netznutzung',
        value: 0.0389,
        unit: 'EURO_KWH',
        sum: 77.8,
        unitSum: 'EURO'
      },
      {
        key: '101',
        name: 'Konzessionsabgabe',
        value: 0.0132,
        unit: 'EURO_KWH',
        sum: 26.4,
        unitSum: 'EURO'
      },
      {
        key: '102',
        name: 'Messdienstleistung',
        value: 0,
        unit: 'EURO_KWH',
        sum: 0,
        unitSum: 'EURO'
      },
      {
        key: '103',
        name: 'Messtellenbetrieb',
        value: 8.58,
        unit: 'EURO_YEARLY',
        sum: 8.58,
        unitSum: 'EURO'
      },
      {
        key: '104',
        name: '§ 19 StromNEV-Umlage',
        value: 0.00305,
        unit: 'EURO_KWH',
        sum: 6.1,
        unitSum: 'EURO'
      },
      {
        key: '105',
        name: 'KWKG-Umlage',
        value: 0.0028,
        unit: 'EURO_KWH',
        sum: 5.6,
        unitSum: 'EURO'
      },
      {
        key: '106',
        name: 'Offshore-Haftungsumlage',
        value: 0.00416,
        unit: 'EURO_KWH',
        sum: 8.32,
        unitSum: 'EURO'
      },
      {
        key: '107',
        name: 'abLa-Umlage',
        value: 0.00005,
        unit: 'EURO_KWH',
        sum: 0.1,
        unitSum: 'EURO'
      },
      {
        key: '300',
        name: 'EEG-Umlage',
        value: 0.06405,
        unit: 'EURO_KWH',
        sum: 128.1,
        unitSum: 'EURO'
      },
      {
        key: '301',
        name: 'Stromsteuer',
        value: 0.0205,
        unit: 'EURO_KWH',
        sum: 41,
        unitSum: 'EURO'
      }
    ],
    totalExpensesPerMonth: 25.166666667,
    totalExpensesPerMonthUnit: 'EURO',
    totalExpensesPerYear: 302,
    totalExpensesPerYearUnit: 'EURO',
    totalExpensesCostPerKwH: 14.671,
    totalExpensesCostsPerKwHUnit: 'CENT_KWH',
    totalExpensesCostPerYear: 8.58,
    totalExpensesCostsPerYearUnit: 'EURO_YEARLY'
  }
};
