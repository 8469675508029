import { Moment } from 'moment';
import * as moment from 'moment';
import { sha256 } from 'js-sha256';
import {
  OrderPayloadIfc,
  SECTION_ENUM,
  ApiPayloadCustomer,
  ApiPayloadBankAccountOwner,
  AddressData,
} from './payload.mbs.types';
import { StepOnePersonalDataIfc } from './step1.mbs.types';
import {
  StepTwoAddressDataIfc,
  RESIDENCE_TIME,
  LongResidenceIfc,
  CONTRACT_ENDING_TYPE,
  CURRENT_CONTRACT_PORTAL_END_DATE_TYPE,
  ShortResidenceIfc,
  LongDynamicTariffIfc,
  ShortDynamicTariffIfc,
} from './step2.mbs.types';
import { StepThreePaymentDataIfc, PAYMENT_METHOD } from './step3.mbs.types';
import { SummaryDataIfc } from './transition.msb.types';
import { MbsProduct } from '../mbsProduct';
import { Injectable } from '@angular/core';
import { SearchValues } from '../interface';
import { CustomerTypeEnum, SectorTypeEnum } from '../enum';
import { MbsOldSupplierCancellationState, MbsSupplyChangeCause } from '@ekso/ekso-types';

export const DateISOFormat = 'YYYY-MM-DDTHH:mm:ss.SSS[Z]';
export const DateSimpleFormat = 'YYYY-MM-DD';
export const DateViewFormat = 'DD.MM.YYYY';
export const legalFormDefault = '1';

@Injectable({
  providedIn: 'root',
})
export class ProductOrderPayloadHelper {
  // tslint:disable-next-line: variable-name
  protected customizePld(currentPld: OrderPayloadIfc): OrderPayloadIfc {
    currentPld.businessConditionsAccepted = true;
    // Object.keys(summaryData)
    // .filter(k => k !== 'mailOptin')
    // .every(k => summaryData[k] === true);
    return currentPld;
  }

  private convertToMoment(dateString: string): moment.Moment {
    return moment(dateString, "DD.MM.YYYY");
  }

  public getNetworkDateISOFormat(m: Moment | string): string {
    if (moment.isMoment(m)) {
      return m.format(DateISOFormat);
    } else {
      return moment(m, "DD-MM-YYYY").format(DateISOFormat);
    }
  }

  public getNetworkDateSimpleFormat(m: Moment): string {
    if (moment.isMoment(m)) {
      return m.format(DateSimpleFormat);
    } else {
      throw new Error('Incorrect Date format');
    }
  }

  public getViewDateSimpleFormat(m: Moment): string {
    if (moment.isMoment(m)) {
      return m.format(DateViewFormat);
    } else {
      throw new Error('Incorrect Date format');
    }
  }

  public getNetworkOptInDateFormat(m: Moment): string {
    if (moment.isMoment(m)) {
      return m.format(DateSimpleFormat);
    } else {
      throw new Error('Incorrect Date format');
    }
  }

  public generatePayload(
    // startData: StartPageDataIfc,
    searchValues: SearchValues,
    ratingScore: number,
    stepOneData: StepOnePersonalDataIfc,
    stepTwoData: StepTwoAddressDataIfc,
    stepThreeData: StepThreePaymentDataIfc,
    summaryData: SummaryDataIfc,
    product: MbsProduct,
    dataCompleteness: boolean,
    currentSection: SECTION_ENUM,
    businessParterNumber: string
  ): OrderPayloadIfc {
    const ret = {} as OrderPayloadIfc;


    /* Banking & Payment data */
    if (stepThreeData.paymentSelection === PAYMENT_METHOD.SEPA) {
      ret.bankAccountIban = stepThreeData.paymentDetails.iban.replace(/\s/g, '');
      ret.bankAccountOwner = this.payloadBankOwnerData(stepOneData, summaryData);
      ret.bankAccountOwnerName = this.payloadBankOwnerName(searchValues.customerType, ret.bankAccountOwner);
      ret.bankAccountOwner = undefined;
    } else if (stepThreeData.paymentSelection === PAYMENT_METHOD.TRANSFER) {
      /* No bank data available */
    }

    ret.customer = this.payloadCustomerGeneration(stepOneData, summaryData, businessParterNumber);

    if (ratingScore) {
      ret.ratingScore = ratingScore;
    }

    ret.deliveryPointAddress = this.getDeliveryAddress(stepTwoData);

    ret.consumptionPrognosisKwh = '' + searchValues.consumption;

    ret.deliveryPointMeteringDeviceNumber = stepTwoData.meterNumber
      ? stepTwoData.meterNumber
      : undefined;

    ret.marketLocation = stepTwoData.marketLocation
      ? stepTwoData.marketLocation
      : undefined;

    ret.section = currentSection;
    ret.sectorType = searchValues.sectorType;

    if (searchValues.sectorType === SectorTypeEnum.DYNAMIC) {
      const moveInData = stepTwoData.moveIn as LongDynamicTariffIfc | ShortDynamicTariffIfc;
      const convertedSupplierOldCancellationDate = this.convertToMoment(moveInData.supplierOldCancellationDate);
      ret.supplyChangeCause = MbsSupplyChangeCause.supplierChange;
      ret.supplyBeginDate = this.getNetworkDateISOFormat(moveInData.moveInDate);

      switch (moveInData.currentContractEndingType) {
        case CONTRACT_ENDING_TYPE.SELF_MADE: {
          ret.oldSupplierCancellationState = MbsOldSupplierCancellationState.oldSupplierCanceled;
          ret.supplierOldCancellationDate = this.getNetworkDateISOFormat(
            convertedSupplierOldCancellationDate);
          break;
        }
        case CONTRACT_ENDING_TYPE.BY_PORTAL: {
          ret.oldSupplier = (
            stepTwoData.moveIn as LongResidenceIfc
          ).currentProvider;
          ret.oldSupplierCancellationState = MbsOldSupplierCancellationState.oldSupplierNotCanceled;
          ret.supplierOldCancellationDate = this.getNetworkDateISOFormat(convertedSupplierOldCancellationDate);
          break;
        }
      }
    }
    else if (stepTwoData.residencePeriodSelection === RESIDENCE_TIME.LONGER_THAN_6_WKS) {
      ret.supplyChangeCause = MbsSupplyChangeCause.supplierChange;

      switch ((stepTwoData.moveIn as LongResidenceIfc).currentContractEndingType) {
        case CONTRACT_ENDING_TYPE.BY_PORTAL: {
          ret.oldSupplier = (stepTwoData.moveIn as LongResidenceIfc).currentProvider;
          ret.oldSupplierCancellationState = MbsOldSupplierCancellationState.oldSupplierNotCanceled;
          switch ((stepTwoData.moveIn as LongResidenceIfc).currentContractEndingMethod) {
            case CURRENT_CONTRACT_PORTAL_END_DATE_TYPE.ON_SELECTED_DATE: {
              ret.supplierOldCancellationDate = this.getNetworkDateISOFormat((stepTwoData.moveIn as LongResidenceIfc).portalEndDate);
              ret.supplyBeginDate = this.getNetworkDateISOFormat((stepTwoData.moveIn as LongResidenceIfc).portalEndDate.clone().add(1, 'day'));
              break;
            }
            case CURRENT_CONTRACT_PORTAL_END_DATE_TYPE.ASAP: {
              ret.supplierOldCancellationDate = undefined;
              ret.supplyBeginDate = undefined;
              break;
            }
          }
          break;
        }
        case CONTRACT_ENDING_TYPE.SELF_MADE: {
          ret.oldSupplierCancellationState = MbsOldSupplierCancellationState.oldSupplierCanceled;
          ret.supplierOldCancellationDate = this.getNetworkDateISOFormat((stepTwoData.moveIn as LongResidenceIfc).selfEndDate);
          ret.supplyBeginDate = this.getNetworkDateISOFormat((stepTwoData.moveIn as LongResidenceIfc).selfEndDate.clone().add(1, 'day'));
          break;
        }
      }
    } else if (stepTwoData.residencePeriodSelection === RESIDENCE_TIME.SHORTER_THAN_6_WKS) {
      ret.supplyChangeCause = MbsSupplyChangeCause.move;
      ret.supplyBeginDate = this.getNetworkDateISOFormat((stepTwoData.moveIn as ShortResidenceIfc).moveInDate);
      /* There is no previous supplier, explicitly wipe it out */
      ret.supplierOldCancellationDate = undefined;
      ret.oldSupplier = undefined;
      ret.oldSupplierCancellationState = undefined;
    }

    ret.tarifId = product.apiIfc[product.getActiveRuntimeIndex()].tariff.id;
    ret.tarifName = product.apiIfc[product.getActiveRuntimeIndex()].tariff.nameExtern;

    if (stepTwoData.otherInvoiceAddressEnabled && stepTwoData.otherInvoiceAddress) {
      ret.deviatingCustomer = {
        firstname: stepTwoData.otherInvoiceAddress.name,
        lastname: stepTwoData.otherInvoiceAddress.surname,
        legalForm: legalFormDefault,
      };

      ret.deviatingCustomerAddress = {
        city: stepTwoData.otherInvoiceAddress.city,
        postalCode: stepTwoData.otherInvoiceAddress.postcode,
        street: stepTwoData.otherInvoiceAddress.street,
        housenumber: stepTwoData.otherInvoiceAddress.houseNumber,
      };
    }

    ret.addOns = {
      paymentType: stepThreeData.paymentSelection,
      residenceTime: stepTwoData.residencePeriodSelection,
      contractEndingState:
        stepTwoData.residencePeriodSelection ===
          RESIDENCE_TIME.LONGER_THAN_6_WKS
          ? (stepTwoData.moveIn as LongResidenceIfc).currentContractEndingType
          : undefined,
      contractEndingDate:
        stepTwoData.residencePeriodSelection ===
          RESIDENCE_TIME.LONGER_THAN_6_WKS
          ? (stepTwoData.moveIn as LongResidenceIfc).currentContractEndingMethod
          : undefined,
      allDataProvided: dataCompleteness,
      section: currentSection,
      acquisitionId: sha256(ret.customer.email || '' + ret.customer.firstname + ret.customer.lastname + 'Brillant'),
    };

    ret.contractDate = this.getNetworkDateSimpleFormat(moment());
    ret.communicationType = stepOneData.emailCommunication;
    ret.email = stepOneData.email || undefined;

    return this.customizePld(ret);
  }

  private payloadCustomerGeneration(
    stepOneData: StepOnePersonalDataIfc,
    summaryData: SummaryDataIfc,
    businessParterNumber: string
  ): ApiPayloadCustomer {
    return {
      firstname: stepOneData.name || undefined,
      lastname: stepOneData.surname || undefined,
      company: stepOneData.company || undefined,
      nameaddition: stepOneData.nameaddition || undefined,
      email: stepOneData.email || undefined,
      password: stepOneData.password || undefined,
      emailCommunication: {
        channelAddress: stepOneData.email,
        optInDate: summaryData.mailOptin
          ? this.getNetworkOptInDateFormat(moment())
          : undefined,
        optInOriginalWording: summaryData.mailOptin
          ? 'Optin mail text'
          : undefined,
      },
      phoneNumber: stepOneData.phone,
      phoneCommunication: {
        channelAddress: stepOneData.phone,
        optInDate: summaryData.mailOptin
          ? this.getNetworkOptInDateFormat(moment())
          : undefined,
        optInOriginalWording: summaryData.mailOptin
          ? 'Optin phone text'
          : undefined,
      },
      // salutation: '' this.generateSalutationFromGender(stepOneData.gender),
      legalForm: stepOneData.legalForm || legalFormDefault,
      dateOfBirth: stepOneData.birthDate
        ? this.getNetworkDateISOFormat(stepOneData.birthDate)
        : undefined,
      /* YYYY-MM-DD  YYYY-MM-DDTHH:MM:ss.mmmmm */
      businessPartnerNumber: businessParterNumber,
    };
  }

  private payloadBankOwnerData(
    stepOneData: StepOnePersonalDataIfc,
    summaryData: SummaryDataIfc
  ): ApiPayloadBankAccountOwner {
    const ret: ApiPayloadBankAccountOwner = {} as ApiPayloadBankAccountOwner;
    ret.firstname = stepOneData.name;
    ret.lastname = stepOneData.surname;
    ret.company = stepOneData.company;
    ret.email = stepOneData.email;
    ret.nameaddition = stepOneData.nameaddition;
    ret.emailCommunication = {
      channelAddress: stepOneData.email,
      optInDate: summaryData.mailOptin
        ? this.getNetworkOptInDateFormat(moment())
        : undefined,
      optInOriginalWording: '',
    };
    return ret;
  }

  private payloadBankOwnerName(
    customerType: string,
    bankAccountOwner: ApiPayloadBankAccountOwner
  ): string {
    if (customerType === CustomerTypeEnum.PRIVATE) {
      return `${bankAccountOwner.firstname} ${bankAccountOwner.lastname}`;
    } else {
      return bankAccountOwner.nameaddition
        ? `${bankAccountOwner.company} ${bankAccountOwner.nameaddition}`
        : `${bankAccountOwner.company}`;
    }
  }

  private getDeliveryAddress(stepTwoData: StepTwoAddressDataIfc): AddressData {
    return {
      city: stepTwoData.city,
      housenumber: stepTwoData.houseNumber,
      postalCode: stepTwoData.postcode,
      street: stepTwoData.street,
    };
  }

  public getSupplyBeginDateFromFormData(stepTwoData: StepTwoAddressDataIfc) {
    if (stepTwoData.residencePeriodSelection === RESIDENCE_TIME.LONGER_THAN_6_WKS) {
      switch ((stepTwoData.moveIn as LongResidenceIfc).currentContractEndingType) {
        case CONTRACT_ENDING_TYPE.BY_PORTAL: {
          switch ((stepTwoData.moveIn as LongResidenceIfc).currentContractEndingMethod) {
            case CURRENT_CONTRACT_PORTAL_END_DATE_TYPE.ON_SELECTED_DATE: {
              return this.getViewDateSimpleFormat((stepTwoData.moveIn as LongResidenceIfc).portalEndDate.clone().add(1, 'day'));
            }
            case CURRENT_CONTRACT_PORTAL_END_DATE_TYPE.ASAP: {
              return undefined;
            }
          }
          break;
        }
        case CONTRACT_ENDING_TYPE.SELF_MADE: {
          return this.getViewDateSimpleFormat((stepTwoData.moveIn as LongResidenceIfc).selfEndDate.clone().add(1, 'day'));
        }
      }
    } else if (stepTwoData.residencePeriodSelection === RESIDENCE_TIME.SHORTER_THAN_6_WKS) {
      return this.getViewDateSimpleFormat((stepTwoData.moveIn as ShortResidenceIfc).moveInDate.clone());
    }
  }
}
