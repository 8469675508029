import * as moment from 'moment';
import { OBJECTSTROM_PRODUCT_ID, REGIO_IDS } from './app.consts';
import { CustomerTypeEnum, sectorTypeMapping } from './enum';
import { SECTION_ENUM } from './mbs/transition.msb.types';
import { SectorType  } from '@ekso/ekso-types';

export interface MbsProductHttp {
  isSufficientInformationProvided: boolean;
  expenses?: ExpensesDTO;
  priceInformation: PriceInformationIfc[];
}

const HardcodedBonuses: ProductBonus[] = [
  {
    type: 'plainTextBonus',
    replacement: 'runtime',
    name: ' Monate Preisstabilität',
  },
  {
    type: 'ekoEnergyBonus',
    name: '100 % Ökostrom',
  },
  {
    type: 'plainTextBonus',
    name: 'Online-Kundenbereich'
  },
];

export const ProductsTariffMapping = {
  [SECTION_ENUM.STROM]: {
    [CustomerTypeEnum.BUSINESS]: {
      [sectorTypeMapping.POWER]: [
        {
          name: 'L-Strom',
          ids: [218]
        },
        {
          name: 'L-Strom.pur',
          ids: [124]
        }
      ],
      [sectorTypeMapping.HEATPUMP]: [
        {
          name: 'L-Strom.wärme',
          ids: [214]
        },
        {
          name: 'L-Strom.wärme',
          ids: [215]
        },
      ],
    },
    [CustomerTypeEnum.PRIVATE]: {
      [sectorTypeMapping.POWER]: [
        {
          name: 'L-Strom',
          ids: [217]
        },
        {
          name: 'L-Strom.pur',
          ids: [110]
        },
      ],
      [sectorTypeMapping.HEATPUMP]: [
        {
          name: 'L-Strom.wärme',
          ids: [212]
        },
        {
          name: 'L-Strom.wärme',
          ids: [213]
        },
      ],
    },
  },
  [SECTION_ENUM.GAS]: {
    [CustomerTypeEnum.BUSINESS]: {
      [sectorTypeMapping.GAS]: [
        {
          name: 'L-Gas',
          ids: [220]
        },
        {
          name: 'L-Gas.pur',
          ids: [126]
        },
      ]
    },
    [CustomerTypeEnum.PRIVATE]: {
      [sectorTypeMapping.GAS]: [
        {
          name: 'L-Gas',
          ids: [219]
        },
        {
          name: 'L-Gas.pur',
          ids: [118]
        },
      ]
    },
  }
};

export interface ExpensesDTO {
  expenseEntries: ValueEntryDTO[];
  totalExpensesCostPerKwH: number;
  totalExpensesCostPerYear: number;
  totalExpensesCostsPerKwHUnit: string;
  totalExpensesCostsPerYearUnit: string;
  totalExpensesPerMonth: number;
  totalExpensesPerMonthUnit: string;
  totalExpensesPerYear: number;
  totalExpensesPerYearUnit: string;
}

export interface ValueEntryDTO {
  key: string;
  name: string;
  value: number;
  unit: string;
  sum: number;
  unitSum: string;
}

export interface PriceInformationIfc {
  additionalCalculationData: AdditionalCalculationInformationDTO[];
  charges: ChargesDTO;
  priceCalculation: PriceCalculationDTO;
  tariff: TariffDTO;
}

export interface AdditionalCalculationInformationDTO {
  baseGridPriceOnKwh: number;
  baseGridPriceOnKwhUnit: string;
  expectedPaymentPlanPerMonth: number;
  expectedPaymentPlanPerMonthUnit: string;
  expectedPaymentPlanPerYear: number;
  expectedPaymentPlanPerYearUnit: string;
  msbPriceOnKwh: number;
  msbPriceOnKwhUnit: string;
  netPowerPriceOnKwh: number;
  netPowerPriceOnKwhUnit: string;
  peak: number;
  sumOfExpensesAndCharges: number;
  sumOfExpensesAndChargesUnit: string;
  sumOfExpensesAndGridPricePerYear: number;
  sumOfExpensesAndGridPricePerYearUnit: string;
  sumOfExpensesAndMSB: number;
  sumOfExpensesAndMSBUnit: string;
  tariffId: string;
}

export interface ChargesDTO {
  totalChargesPerKwH: number;
  totalChargesPerKwHUnit: string;
  totalChargesPerYear: number;
  totalChargesPerYearUnit: string;
  chargesEntries: ValueEntryDTO[];
}

export interface PriceCalculationDTO {
  advancePayPlanHeight: number;
  advancePayPlanHeightNet: number;
  regionalPricePeriodStart: number;
  totalCostPerMonth: number;
  totalCostPerMonthInFirstYear: number;
  totalCostPerMonthInFirstYearNet: number;
  totalCostPerMonthNet: number;
  totalCostPerYear: number;
  totalCostPerYearInFirstYear: number;
  totalCostPerYearInFirstYearNet: number;
  totalCostPerYearNet: number;
  totalSavingsPerYear: number;
  totalSavingsPerYearNet: number;
}

export interface TariffDTO {
  cancellationTerm: number;
  cancellationTermTo: string;
  cancellationTermUnit: string;
  energyPriceGross: number;
  energyPriceNet: number;
  energyPriceTax: number;
  id: number;
  name: string;
  nameExtern: string;
  periodEnd: string;
  periodStart: string;
  portalInfoAdditionalPriceDetails: string;
  portalInfoAdditionalTariffDescription: string;
  priceGuarantyEnd: string;
  priceGuarantyFor: number;
  renewalInMonth: number;
  runtimeInMonth: runtimeInMonths;
  tariffType: string;
  boni: BonusDTO[];
  baseFeeNet: number;
  baseFeeTax: number;
  baseFeeGross: number;
}

export interface BonusDTO {
  dueDate: string;
  nameExternal: string;
  payback: string;
  state: string;
  type: ProductFeatureType;
  value: string;  /* TAX not included  >> DISPLAY this one << */
  valueNet: string; /* TAX included */
}

export interface PriceIfc {
  runtime: number;
  yearly: number;
}

export interface PriceFullIfc extends PriceIfc {
  monthly: number;
  rounded: number;
}

export interface PriceDeltaIfc extends PriceIfc {
  delta: boolean;
}

export interface ProductBonus {
  type: ProductFeatureType;
  name: string;
  replacement?: BonusReplacementPart;
  boniOriginal?: Partial<BonusDTO>;
  value?: string;
}

export type ProductFeatureType = 'valueBonus' | 'plainTextBonus' | 'ekoEnergyBonus';
export type BonusReplacementPart = 'runtime' | 'value';
export type runtimeInMonths = 12 | 18 | 24;

export class MbsProduct {
  public apiIfc: PriceInformationIfc[]; // MbsProductHttp;
  public expenses: ExpensesDTO;
  public runtimesInMonths: runtimeInMonths[] = [];
  public name: string[] = [];
  public id: number[] = [];
  public prices: PriceFullIfc[] = [];
  public pricesDelta: PriceDeltaIfc[] = [];
  public features: Array<ProductBonus[]> = [];
  public priceGuarantee: number[] = [];
  public priceGuaranteeEnd: Date[] = [];
  public grundpreis: number[] = [];
  public grundpreisNet: number[] = [];
  public arbeitspreis: number[] = [];
  public netpreis: number[] = [];
  public totalCostPerMonthNet: number[] = [];
  public totalCostPerYearNet: number[] = [];
  public isSufficientInformationProvided: boolean;
  public activeRuntimeMonths: runtimeInMonths;
  public activeRuntimeIndex: number;
  private failed = false;
  public section: SectorType;
  // TODO: add city??
  City: string;

  static isPayloadAProduct(ifc: MbsProductHttp) {
    return ifc.priceInformation && Array.isArray(ifc.priceInformation) && ifc.priceInformation.length > 0;
  }

  static areProductsSame(p1: MbsProduct, p2: MbsProduct): boolean {
    return p1.getActiveRuntime() === p2.getActiveRuntime() &&
      p1.section === p2.section &&
      p1.id.every((id, idx) => id === p2.id[idx]) &&
      p1.name.every((name, idx) => name === p2.name[idx]);
  }

  constructor(section: SectorType, ifc: MbsProductHttp, runtimes?: runtimeInMonths, heatPumpTariffId?: number) {
    this.section = section;
    if (!ifc) {
      return;
    }

    this.apiIfc = ifc.priceInformation;
    this.isSufficientInformationProvided = ifc.isSufficientInformationProvided;

    if (ifc.priceInformation && ifc.priceInformation.length > 0) {
      ifc.priceInformation.forEach((priceInfo, runtimeIdx) => {
        /* Each priceInformation array entry is associated with single runtime */
        this.runtimesInMonths.push(priceInfo.tariff.runtimeInMonth);
        /* Fill data pre runtime */
        this.name[runtimeIdx] = priceInfo.tariff.nameExtern;
        this.id[runtimeIdx] = priceInfo.tariff.id;
        this.priceGuarantee[runtimeIdx] = priceInfo.tariff.priceGuarantyFor;
        this.priceGuaranteeEnd[runtimeIdx] = moment(+ priceInfo.tariff.priceGuarantyEnd).toDate();
        this.prices[runtimeIdx] = {
          runtime: this.runtimesInMonths[runtimeIdx],
          monthly: priceInfo.priceCalculation.totalCostPerMonth,
          yearly: priceInfo.priceCalculation.totalCostPerYear,
          rounded: Math.ceil(priceInfo.priceCalculation.totalCostPerMonth),
        };
        this.grundpreis[runtimeIdx] = priceInfo.tariff.baseFeeGross;
        this.grundpreisNet[runtimeIdx] = priceInfo.tariff.baseFeeNet;
        this.arbeitspreis[runtimeIdx] = priceInfo.tariff.energyPriceGross;
        this.netpreis[runtimeIdx] = priceInfo.tariff.energyPriceNet;
        this.totalCostPerMonthNet[runtimeIdx] = priceInfo.priceCalculation.totalCostPerMonthNet;
        this.totalCostPerYearNet[runtimeIdx] = priceInfo.priceCalculation.totalCostPerYearNet;
        this.buildProductFeatures(priceInfo, runtimeIdx);
      });
      this.pricesDelta = this.calcPriceDelta();
    }

    if (heatPumpTariffId) {
      this.setRuntimeByTariffId(this.id.findIndex(x => x === heatPumpTariffId));
    } else {
      runtimes ? this.setActiveRuntime(runtimes) : this.setRuntimeByTariffId();
    }
  }

  public toMbsHttpProduct(): MbsProductHttp {
    return {
      expenses: this.expenses,
      isSufficientInformationProvided: this.isSufficientInformationProvided,
      priceInformation: this.apiIfc
    };
  }

  failProduct() {
    this.isSufficientInformationProvided = true;
    this.runtimesInMonths = [];
    this.apiIfc = [];

    this.failed = true;
  }

  isFailed(): boolean {
    return this.failed;
  }

  private getApiBonusValue(bonus: BonusDTO): string {
    return bonus.value ? bonus.value.replace(',', '.') : '';
  }

  getProductName() {
    return this.section !== SectorType.HEATPUMP ? this.name[this.activeRuntimeIndex] : 'L-Strom.wärme';
  }

  getProductId() {
    return this.id[this.activeRuntimeIndex];
  }


  private buildProductFeatures(priceInfo: PriceInformationIfc, runtimeIdx: number) {
    if (!this.features[runtimeIdx]) {
      this.features[runtimeIdx] = [];
    }
    const ft = priceInfo.tariff.boni.map<ProductBonus>(bonus => {
      return {
        boniOriginal: bonus,
        name: bonus.nameExternal,
        type: 'valueBonus',
        replacement: 'value',
        value: this.getApiBonusValue(bonus)
      };
    });
    this.features[runtimeIdx].push(...ft);
    this.features[runtimeIdx].push(...this.buildBonuses());
  }

  setActiveRuntime(n: runtimeInMonths, indexByTariffId?: number) {
    this.activeRuntimeMonths = n;
    this.setActiveRuntimeIndex(indexByTariffId);
  }

  private setActiveRuntimeIndex(activeIndexByTariffId?: number) {
    this.activeRuntimeIndex = activeIndexByTariffId || this.getDataIndexByRuntime(this.activeRuntimeMonths);
  }

  public setActiveRuntimeIndexForHeatPump(index: number) {
    this.activeRuntimeIndex = index;
  }

  getActiveRuntimeIndex() {
    return this.activeRuntimeIndex;
  }

  private buildBonuses(): ProductBonus[] {
    return HardcodedBonuses.map(b => {
      const bc = { ...b };
      if (bc.type === 'ekoEnergyBonus' && this.section === 'GAS') {
        bc.name = '100 % Ökogas';
        return bc;
      } else {
        return bc;
      }
    });
  }

  getActiveRuntimeInMonths(): runtimeInMonths {
    return this.activeRuntimeMonths;
  }

  getPricesForuntime(runtime: number): { runtime: number; monthly: number; yearly: number; } {
    return this.prices.find(pr => pr.runtime === runtime);
  }

  getDataIndexByRuntime(runtime: runtimeInMonths): number {
    return this.runtimesInMonths.findIndex(rt => rt === runtime);
  }

  getPriceGuarantee() {
    if (this.isRegioProduct()) {
      return this.getPriceGuaranteeEnd();
    } else {
      return this.priceGuarantee[this.activeRuntimeIndex];
    }
  }

  getFeatures() {
    return this.features[this.activeRuntimeIndex];
  }

  getPriceMonthly(rounded = true) {
    try {
      return (rounded ? this.prices[this.activeRuntimeIndex].rounded : this.prices[this.activeRuntimeIndex].monthly);
    } catch (e) {
      return (rounded ? this.prices[0].rounded : this.prices[0].monthly);
    }
  }

  getPriceYearly() {
    return this.prices[this.activeRuntimeIndex].yearly;
  }

  getBaseFeeGross() {
    return this.grundpreis[this.activeRuntimeIndex];
  }

  getBaseFeeNet() {
    return this.grundpreisNet[this.activeRuntimeIndex];
  }

  getEnergyPriceGross() {
    return this.arbeitspreis[this.activeRuntimeIndex];
  }

  getEnergyPriceNet() {
    return this.netpreis[this.activeRuntimeIndex];
  }

  getPriceMonthlyNet() {
    return this.totalCostPerMonthNet[this.activeRuntimeIndex];
  }

  getCostPerYearWithBonuses() {
    return this.pricesDelta[this.activeRuntimeIndex].yearly;
  }

  getCostPerYearNet() {
    return this.totalCostPerYearNet[this.activeRuntimeIndex];
  }

  getPriceGuaranteeEnd(): string {
    return moment(this.priceGuaranteeEnd[this.activeRuntimeIndex]).format('DD.MM.YYYY');
  }

  isProductCorrupted() {
    return (!this.isSufficientInformationProvided) || /* Interface explicitly claims, there were no enough information */
      (!this.apiIfc) || /* No external HTTP feed was provided */
      (!this.apiIfc || this.apiIfc.length === 0); /* price info is missing or does not contain products */
  }

  getBonusByName(name: string): ProductBonus {
    const idx = this.features[this.getDataIndexByRuntime(this.getActiveRuntimeInMonths())].findIndex(feat => {
      return feat.name.toLowerCase().includes(name.toLowerCase());
    });
    if (idx !== -1) {
      return this.features[this.getDataIndexByRuntime(this.getActiveRuntimeInMonths())][idx];
    } else {
      return null;
    }
  }

  private calcPriceDelta(): PriceDeltaIfc[] {
    const ret: PriceDeltaIfc[] = [];
    // tslint:disable-next-line: variable-name
    this.runtimesInMonths.forEach((_rtVal, idx) => {
      const boniIdx = this.apiIfc[idx].tariff.boni.findIndex(b => b.nameExternal.toLowerCase() === 'neukundenbonus');
      let sumOfBonuses = 0;
      this.features[idx]
        .filter(f => f.type === 'valueBonus')
        .forEach(b => {
          sumOfBonuses += +b.value;
        });
      if (boniIdx !== -1) {
        ret.push({
          yearly:
            +(Math.round((+(this.prices[idx].yearly - (sumOfBonuses)) + Number.EPSILON) * 100) / 100).toFixed(2),
          runtime: this.runtimesInMonths[idx],
          delta: true,
        });
      } else {
        ret.push({
          yearly: this.prices[idx].yearly,
          runtime: this.runtimesInMonths[idx],
          delta: false
        });
      }
    });
    return ret;
  }

  getActiveRuntime(): runtimeInMonths {
    return this.activeRuntimeMonths;
  }

  private getIdxOfObjectstromProduct(): number {
    return this.apiIfc.findIndex((v: PriceInformationIfc) => v.tariff.id === OBJECTSTROM_PRODUCT_ID);
  }

  private setRuntimeByTariffId(indexByTariffId?: number): void {
    const idx = indexByTariffId || this.getIdxOfObjectstromProduct();

    if (idx !== -1) {
      this.setActiveRuntime(this.apiIfc[idx].tariff.runtimeInMonth, indexByTariffId);
    } else {
      this.setActiveRuntime(this.apiIfc[0].tariff.runtimeInMonth);
    }
  }

  isRegioProduct(): boolean {
    return REGIO_IDS.some(rid => rid === this.getProductId());
  }
}
