import { EtrackerModule } from './etracker/etracker.module';
import { ReactiveFormsModule } from '@angular/forms';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { LoaderService } from './services/loader.service';
import { ErrorService } from './services/error.service';
import { SearchFormService } from './components/search-form/search-form.service';
import { CommonModule } from '@angular/common';
import { CustomBreakPointsProvider } from './common/custom-breakpoints';
import { CustomFlexDirective } from './common/custom-flex.directive';
import { CustomClassDirective } from './common/custom-class.directive';
import { CustomLayoutDirective } from './common/custom-layout.directive';
import { CustomLayoutAlignDirective } from './common/custom-align.directive';
import { CustomLayoutGapDirective } from './common/custom-layoutgap.directive';
import { SharedConfiguration } from './shared.configuration';
import { FormatDate, FormatPrice, SuperscriptedNumber } from './pipes/format.pipes';
import { ActionReducer, MetaReducer, StoreModule } from '@ngrx/store';
import { searchProductsReducer } from './state/app.reducers';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { localStorageSync } from 'ngrx-store-localstorage';
import { AppEffects } from './state/app.effects';
import { EffectsModule } from '@ngrx/effects';
import { AppService } from './state/app.service';
import { RouterModule } from '@angular/router';
import { TooltipModule, TooltipOptions } from 'ng2-tooltip-directive';

export function createTranslateLoader(http: HttpClient, sharedConfig: SharedConfiguration) {
  return new TranslateHttpLoader(http, sharedConfig.assetPath + '/i18n/', '.json?ver=' + new Date().getTime());
}

export function localStorageSyncReducer(reducer: ActionReducer<any>): ActionReducer<any> {
  return localStorageSync({keys: ['lswContractClosing'], rehydrate: true, storage: sessionStorage})(reducer);
}
const metaReducers: Array<MetaReducer<any, any>> = [localStorageSyncReducer];

export const tooltipOptions: TooltipOptions = {
  tooltipClass: 'lsw-tooltip',
  displayMobile: true,
  displayTouchscreen: true,
  placement: 'right'
}

@NgModule({
  imports: [
    EtrackerModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    BrowserModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient, SharedConfiguration]
      }
    }),
    StoreModule.forRoot({lswContractClosing: searchProductsReducer}, {metaReducers}),
    StoreDevtoolsModule.instrument(),
    EffectsModule.forRoot([ AppEffects ]),
    RouterModule.forRoot([], {}),
    TooltipModule.forRoot(tooltipOptions)
  ],
  exports: [
    EtrackerModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    TranslateModule,
    CommonModule,
    CustomClassDirective,
    CustomFlexDirective,
    CustomLayoutDirective,
    CustomLayoutAlignDirective,
    CustomLayoutGapDirective,
    SuperscriptedNumber,
    FormatPrice,
    FormatDate,
    RouterModule,
    TooltipModule
  ],
  declarations: [
    CustomClassDirective,
    CustomFlexDirective,
    CustomLayoutDirective,
    CustomLayoutAlignDirective,
    CustomLayoutGapDirective,
    SuperscriptedNumber,
    FormatPrice,
    FormatDate,
  ],
  providers: [
    AppService,
    SearchFormService,
    LoaderService,
    ErrorService,
    CustomBreakPointsProvider,
    CustomClassDirective,
    FormatPrice,
    FormatDate
  ]
})
export class SharedModule {
  static forRoot(
    sharedConfiguration: SharedConfiguration
  ): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [
        {
          provide: SharedConfiguration,
          useValue: sharedConfiguration
        }
      ]
    };
  }
}
