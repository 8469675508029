import { Component, ElementRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LoaderService } from '../../../common/src/lib/services/loader.service';
import { SearchFormAppConfig } from '../../../common/src/lib/_models/interface';
import { SearchFormService } from '../../../common/src/lib/components/search-form/search-form.service';
import { AppSettingsService } from '../../../settings/app-settings.service';

@Component({
  selector: 'app-lsw-search',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  maintenanceMode$ = AppSettingsService.isMaintenanceModeOn();
  searchFormConf: SearchFormAppConfig;
  productsUrl: string;
  apiUrl: string;

  constructor(
    public translate: TranslateService,
    private elementRef: ElementRef,
    public loaderService: LoaderService,
    private searchFormService: SearchFormService
  ) {
    this.searchFormConf =
      this.searchFormService.generateInitialSearchFormAppProperties(
        this.elementRef.nativeElement
      );
    this.apiUrl = this.elementRef.nativeElement.getAttribute('apiUrl');
    this.productsUrl =
      this.elementRef.nativeElement.getAttribute('productsUrl');
    translate.setDefaultLang('de');
    translate.use(this.searchFormConf.lang);
  }
}
