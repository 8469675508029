import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { OrderPayloadIfc, SECTION_ENUM } from '../../_models/mbs/payload.mbs.types';
import { ApiProvidersIfc } from '../../_models/mbs/step2.mbs.types';
import { CreditScoringIfcResult, MockApiKeyword, CreditScoringBusinessPayloadIfc, CreditScoringPrivatePayloadIfc } from '../../_models/mbs/transition.msb.types';
import { MbsProductHttp } from '../../_models/mbsProduct';
import { ApiIfc } from './api.mbs.abstract.class';
import { HttpApi } from './impl/http.imp';
import { NonHttpApiImpl } from './impl/mock.impl';
import { SectorTypeEnum, SimplifiedHttpResponse } from '../../_models/enum';

@Injectable({
  providedIn: 'root',
})
export class ApiMbsService extends ApiIfc {
  constructor(private httpApi: HttpApi, private mockApi: NonHttpApiImpl) {
    super();
  }

  getStreets(
    url: string,
    postalCode: string,
    city: string
  ): Observable<string[]> {
    if (url === MockApiKeyword) {
      return this.mockApi.getStreets(url, postalCode, city);
    } else {
      return this.httpApi.getStreets(url, postalCode, city);
    }
  }

  finishAcquisition(
    url: string,
    pld: OrderPayloadIfc
  ): Observable<SimplifiedHttpResponse> {
    if (url === MockApiKeyword) {
      return this.mockApi.finishAcquisition(url, pld);
    } else {
      return this.httpApi.finishAcquisition(url, pld);
    }
  }

  validatePostalCode(url: string, postalCode: string): Observable<string[]> {
    if (url === MockApiKeyword) {
      return this.mockApi.validatePostalCode(url, postalCode);
    } else {
      return this.httpApi.validatePostalCode(url, postalCode);
    }
  }

  getProviders(
    url: string,
    section: SECTION_ENUM
  ): Observable<ApiProvidersIfc[]> {
    if (url === MockApiKeyword) {
      return this.mockApi.getProviders(url);
    } else {
      return this.httpApi.getProviders(url, section);
    }
  }

  userExisting(url: string, userName: string): Observable<boolean> {
    if (url === MockApiKeyword) {
      return this.mockApi.userExisting(url, userName);
    } else {
      return this.httpApi.userExisting(url, userName);
    }
  }

  getCreditScoringForBusiness(
    url: string,
    scoring: CreditScoringBusinessPayloadIfc
  ): Observable<CreditScoringIfcResult> {
    if (url === MockApiKeyword) {
      return this.mockApi.getCreditScoringForBusiness(url, scoring);
    } else {
      return this.httpApi.getCreditScoringForBusiness(url, scoring);
    }
  }

  getCreditScoringForPrivate(
    url: string,
    scoring: CreditScoringPrivatePayloadIfc
  ): Observable<CreditScoringIfcResult> {
    if (url === MockApiKeyword) {
      return this.mockApi.getCreditScoringForPrivate(url, scoring);
    } else {
      return this.httpApi.getCreditScoringForPrivate(url, scoring);
    }
  }

  checkIfUserCanBeRegistered(
    url: string,
    businessPartnerNumber: string,
    email: string
  ): Observable<any> {
    if (url === MockApiKeyword) {
      return this.mockApi.checkIfUserCanBeRegistered(
        url,
        businessPartnerNumber,
        email
      );
    } else {
      return this.httpApi.checkIfUserCanBeRegistered(
        url,
        businessPartnerNumber,
        email
      );
    }
  }

  getProductByBasicInfo(
    url: string,
    zipCode: string,
    consumption: string,
    section: SECTION_ENUM,
    isPrivate: boolean,
    sector: SectorTypeEnum,
    date: string
  ): Observable<MbsProductHttp> {
    if (url === MockApiKeyword) {
      return this.mockApi.getProductByBasicInfo(
        url,
        zipCode,
        consumption,
        section
      );
    } else {
      if (!consumption) {
        throw throwError('consumption can not be empty');
      }
      return this.httpApi.getProductByBasicInfo(
        url,
        zipCode,
        consumption,
        section,
        isPrivate,
        sector,
        date
      );
    }
  }

  getProduct(
    url: string,
    zipCode: string,
    consumption: string,
    city: string,
    street: string,
    houseNo: string,
    section: SECTION_ENUM,
    isPrivate: boolean,
    sector: SectorTypeEnum,
    date: string
  ): Observable<MbsProductHttp> {
    if (url === MockApiKeyword) {
      return this.mockApi.getProduct(
        url,
        zipCode,
        consumption,
        city,
        street,
        houseNo,
        section
      );
    } else {
      if (!consumption) {
        throw throwError('consumption can not be empty');
      }
      return this.httpApi.getProduct(
        url,
        zipCode,
        consumption,
        city,
        street,
        houseNo,
        section,
        isPrivate,
        sector,
        date
      );
    }
  }
}
