import { createAction, props } from '@ngrx/store';
import {
  Product,
  SearchValues,
  QueryLevels,
  HeatPumpValues,
} from '../_models/interface';
import { LswOption } from '@ui/lsw-lib';
import { MbsProduct, runtimeInMonths } from '../_models/mbsProduct';
import { SectorTypeEnum } from '../_models/enum';

export interface SearchProductConfig {
  featuresUrl: string;
}

export const resetState = createAction('[Products] Reset State');
export const searchProducts = createAction(
  '[Products] Search Products',
  props<{
    searchValues: SearchValues;
    apiUrl: string;
    queryLevels: QueryLevels;
    shouldRedirect: boolean;
    heatPumpValues: HeatPumpValues;
    config: SearchProductConfig;
  }>()
);

export const setAdditionalSearchFormData = createAction(
  '[Products] Set Additional Form Data',
  props<{
    streets: LswOption[];
    street: string;
    showStreetAlert: boolean;
    cities: LswOption[];
    city: string;
    queryLevels: QueryLevels;
  }>()
);

export const setProducts = createAction(
  '[Products] Set Products',
  props<{
    products: Product[];
    basicSupplyProduct: Product;
    mbsProducts: MbsProduct[];
    shouldRedirect: boolean;
    queryLevels: QueryLevels;
  }>()
);

export const hideStreetAlert = createAction('[Products] Hide Street alert');
export const updateSearchValues = createAction(
  '[Products] Update Search Values',
  props<{ searchValues: SearchValues }>()
);
export const updateHeatPumpValues = createAction(
  '[Products] Update Heat Pump Values',
  props<{ heatPumpValues: HeatPumpValues }>()
);
export const updateDynamicValue = createAction(
  '[Products] Update Dynamic tariff Value',
  props<{ dynamicTariffValue: boolean }>()
);
export const resetStreet = createAction('[Products] Reset Streets');
export const resetCity = createAction('[Products] Reset Streets');
export const resetProducts = createAction('[Products] Reset Products');
export const resetQueryLevels = createAction('[Products] Reset Query Levels');
export const selectProduct = createAction(
  '[Products] Select Products',
  props<{ selectedProduct: Product }>()
);
export const selectMbsProduct = createAction(
  '[Products] Select MBS Products',
  props<{ selectedMbsProduct: MbsProduct }>()
);
export const resetSelectProduct = createAction(
  '[Products] Reset Selected Products'
);
export const updateSelectedProduct = createAction(
  '[Products] Update Selected Product',
  props<{
    apiUrl: string;
    online: boolean;
    eco: boolean;
    useLt: boolean;
    consumptionLt: number;
    id?: string;
  }>()
);
export const getPatchingProduct = createAction(
  '[Products] Patch Product',
  props<{
    apiUrl: string;
    online: boolean;
    eco: boolean;
    useLt: boolean;
    consumptionLt: number;
    id: string;
  }>()
);
export const updateProductsWithPatch = createAction(
  '[Products] Update products with patch',
  props<{
    products: Product[];
    selected: Product;
  }>()
);
export const updateSearchDate = createAction(
  '[Products] Update SearchValue Date',
  props<{ moveInDate: Date }>()
);
export const updateProducts = createAction(
  '[Products] Update Products',
  props<{ products: Product[] }>()
);
export const setBasicSupplyProduct = createAction(
  '[Products] Set BasicSupplyProduct',
  props<{ basicSupplyProduct: Product }>()
);
export const setProductsError = createAction('[Products] Set Product Error');
export const resetProductsError = createAction(
  '[Products] Reset Product Error'
);
export const updateMbsProduct = createAction(
  '[Products] Set MBS runtime',
  props<{
    mbsProduct: MbsProduct;
    runtime: runtimeInMonths;
    section: SectorTypeEnum;
  }>()
);
