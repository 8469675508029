import { BusinessPartnerType } from './business-partner-type';
import { ContractNumberAndTypeInvalidMessage } from './contract-number-and-type-invalid-message.enum';

export class InvalidContractNumberError extends Error {
  constructor(msg: string) {
    super(msg);

    // Set the prototype explicitly.
    Object.setPrototypeOf(this, InvalidContractNumberError.prototype);
  }
}
export class ContractNumberAndType {
  private readonly _id: string;
  private readonly _type: BusinessPartnerType;

  private readonly SAPIdPrefix = '8';
  private readonly MBSIdPrefix = 'P';

  private readonly expectedMbsContractNumberLength = 14;
  private readonly expectedSapContractNumberLength = 8;

  get id(): string {
    return this._id;
  }

  get type(): BusinessPartnerType {
    return this._type;
  }

  constructor(contractNumber: string) {
    const value = contractNumber?.toUpperCase().trim();

    if (!value) {
      throw new InvalidContractNumberError(
        ContractNumberAndTypeInvalidMessage.REQUIRED
      );
    } else if (
      value.startsWith(this.MBSIdPrefix) &&
      value.length === this.expectedMbsContractNumberLength
    ) {
      this._type = BusinessPartnerType.MBS;
      this._id = value;
    } else if (
      value.startsWith(this.MBSIdPrefix) &&
      value.length !== this.expectedMbsContractNumberLength
    ) {
      this._type = BusinessPartnerType.MBS;
      this._id = value;
      throw new InvalidContractNumberError(
        ContractNumberAndTypeInvalidMessage.SHOULD_BE_14
      );
    } else if (
      value.startsWith(this.SAPIdPrefix) &&
      value.length === this.expectedSapContractNumberLength
    ) {
      this._type = BusinessPartnerType.SAP;
      this._id = value;
    } else if (
      value.startsWith(this.SAPIdPrefix) &&
      value.length !== this.expectedSapContractNumberLength
    ) {
      this._type = BusinessPartnerType.SAP;
      this._id = value;
      throw new InvalidContractNumberError(
        ContractNumberAndTypeInvalidMessage.SHOULD_BE_8
      );
    } else if (
      !value.startsWith(this.MBSIdPrefix) &&
      !value.startsWith(this.SAPIdPrefix)
    ) {
      throw new InvalidContractNumberError(
        ContractNumberAndTypeInvalidMessage.SHOULD_BE_8_OR_14
      );
    } else {
      throw new InvalidContractNumberError(
        `The contract number: "${value}" is incorrect.`
      );
    }
  }
}
