import { EventEmitter, Injectable, Output } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import {
  resetProducts,
  resetProductsError,
  resetStreet,
  searchProducts,
  selectProduct,
  setProducts,
  setProductsError,
  setAdditionalSearchFormData,
  updateSearchValues,
  updateSelectedProduct,
  resetCity,
  getPatchingProduct,
  updateProductsWithPatch,
} from './app.actions';
import { AppService } from './app.service';
import { getState } from './app.selectors';
import { Store } from '@ngrx/store';
import { EtrackerService } from '../etracker/_services/etracker.service';
import { Product, ProductsFetchResult } from '../_models/interface';
import { of } from 'rxjs';

@Injectable()
export class AppEffects {
  @Output() redirectToProductPage: EventEmitter<any> = new EventEmitter();
  @Output() setInitialStreet: EventEmitter<string> = new EventEmitter();
  @Output() setInitialCity: EventEmitter<string> = new EventEmitter();

  constructor(
    private appService: AppService,
    private eTrackerService: EtrackerService,
    private actions$: Actions,
    private store: Store
  ) {}

  hideProductsErrorOnSearch = createEffect(() =>
    this.actions$.pipe(
      ofType(resetProducts),
      map((_) => {
        return resetProductsError();
      })
    )
  );

  resetProductsOnSearch = createEffect(() =>
    this.actions$.pipe(
      ofType(searchProducts),
      map((_) => {
        return resetProducts();
      })
    )
  );

  getProductsOrStreets = createEffect(() =>
    this.actions$.pipe(
      ofType(searchProducts),
      mergeMap((action) =>
        this.appService
          .getStreetsOrProducts(
            action.apiUrl,
            {
              ...action.searchValues,
              moveInDate: action.searchValues.moveInDate
                ? action.searchValues.moveInDate
                : new Date(),
            },
            action.queryLevels,
            action.heatPumpValues,
            action.config
          )
          .pipe(
            map((res: ProductsFetchResult) => {
              /* Both data sources are drained/done/failed, do not re-query */
              if (
                (res.queryLevels.mbs.done ||
                  res.queryLevels.mbs.drain ||
                  res.queryLevels.mbs.fail) &&
                (res.queryLevels.sap.done ||
                  res.queryLevels.sap.drain ||
                  res.queryLevels.sap.fail)
              ) {
                if (res.products || res.mbsProducts) {
                  EtrackerService.trackForm('formConversion', '01_SearchForm');

                  return setProducts({
                    products: res.products,
                    basicSupplyProduct: res.basicSupplyProduct,
                    mbsProducts: res.mbsProducts,
                    shouldRedirect: action.shouldRedirect,
                    queryLevels: res.queryLevels,
                  });
                } else {
                  return setProductsError();
                }
                /* At least one datastore is still able to fetch stuff */
              } else {
                if (res.cities.length > 0) {
                  this.setInitialCity.emit(res.cities[0].value);
                }

                return setAdditionalSearchFormData({
                  streets: res.streets,
                  street: '',
                  city: res.cities[0] ? res.cities[0].value : '',
                  cities: res.cities,
                  showStreetAlert: false,
                  queryLevels: res.queryLevels,
                });
              }
            }),
            catchError((_) => {
              return of(setProductsError());
            })
          )
      )
    )
  );

  getNewSelectedProduct = createEffect(() =>
    this.actions$.pipe(
      ofType(updateSelectedProduct),
      withLatestFrom(this.store.select(getState)),
      mergeMap(([action, state]) =>
        this.appService
          .getProduct(action.apiUrl, {
            ...state.searchValues,
            online: action.online,
            eco: action.eco,
            productId: state.selectedProduct
              ? state.selectedProduct.ProductID
              : action.id,
            useLt: action.useLt,
            consumptionLt: action.consumptionLt,
          })
          .pipe(
            map((res) => {
              return selectProduct({ selectedProduct: res });
            })
          )
      )
    )
  );

  getPatchedProducts = createEffect(() =>
    this.actions$.pipe(
      ofType(getPatchingProduct),
      withLatestFrom(this.store.select(getState)),
      mergeMap(([action, state]) =>
        this.appService
          .getProduct(action.apiUrl, {
            ...state.searchValues,
            online: action.online,
            eco: action.eco,
            productId: action.id,
            useLt: action.useLt,
            consumptionLt: action.consumptionLt,
          })
          .pipe(
            map((res: Product) => {
              const idx = state.products.findIndex(
                (p) => p.ProductID === res.ProductID
              );
              if (idx !== -1) {
                const prodsTmp = [...state.products];
                prodsTmp[idx] = res;
                return updateProductsWithPatch({
                  products: prodsTmp,
                  selected: res,
                });
              } else {
                return updateProductsWithPatch({
                  products: state.products,
                  selected: res,
                });
              }
            })
          )
      )
    )
  );

  resetProductsOnSearchUpdate$ = createEffect(
    () => () =>
      this.actions$.pipe(
        ofType(updateSearchValues),
        map((_) => {
          return resetProducts();
        })
      )
  );

  redirectToProductPage$ = createEffect(
    () => () =>
      this.actions$.pipe(
        ofType(setProducts),
        map((action) => {
          if (action.shouldRedirect) {
            this.redirectToProductPage.emit(action);
          }
        })
      ),
    { dispatch: false }
  );

  trackProducts$ = createEffect(
    () => () =>
      this.actions$.pipe(
        ofType(setProducts),
        withLatestFrom(this.store.select(getState)),
        map(([_, state]) => {
          state.products.forEach((product) => {
            EtrackerService.trackCommerce(
              'viewProduct',
              this.eTrackerService.createProductTackingObject(
                product,
                state.searchValues,
                false
              )
            );
          });
        })
      ),
    { dispatch: false }
  );

  trackProduct$ = createEffect(
    () => () =>
      this.actions$.pipe(
        ofType(selectProduct),
        withLatestFrom(this.store.select(getState)),
        map(([_, state]) => {
          EtrackerService.trackCommerce(
            'insertToWatchlist',
            this.eTrackerService.createProductTackingObject(
              state.selectedProduct,
              state.searchValues,
              true
            ),
            1
          );
        })
      ),
    { dispatch: false }
  );

  resetStreetFormValue$ = createEffect(
    () => () =>
      this.actions$.pipe(
        ofType(resetStreet),
        map((_) => {
          this.setInitialStreet.emit('');
        })
      ),
    { dispatch: false }
  );

  resetCityFormValue$ = createEffect(
    () => () =>
      this.actions$.pipe(
        ofType(resetCity),
        map((_) => {
          this.setInitialCity.emit('');
        })
      ),
    { dispatch: false }
  );
}
