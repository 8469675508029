import { CommonEnvironmentData } from 'projects/common/environment.interface';

export enum SECTION_ENUM {
  STROM = 'Electricity',
  GAS = 'Gas'
}

export enum PROCESS_ENUM {
  BRILLANT = 1,
  WALLBOX = 2
}

export interface StartPageDataIfc {
  section: SECTION_ENUM;
  postCode: string;
  demand: number;
  city?: string;
  street?: string;
  number?: string;
  process: PROCESS_ENUM;
}

export type RateSelection = 'monthly' | 'yearly';
export interface RateStepSelectionDataIfc {
  runtimeInMonths: number;
  priceStability: number;
  rateType: RateSelection;
}

export interface SummaryDataIfc {
  [k: string]: boolean;
  mailOptin: boolean;
}

export interface CreditScoringBusinessPayloadIfc {
  city: string;
  street: string;
  postcode: string;
  requestReason: 'BD';
  name: string;
}
export interface CreditScoringPrivatePayloadIfc {
  city: string;
  street: string;
  postCode: string;
  requestReason: 'BD';
  dateOfBirth?: string;
  firstName: string;
  lastName: string;
}

export interface CreditScoringIfcResult {
  value: number;
  isAllowed: boolean;
  errorMessage?: string;
}

export const MockApiKeyword = 'TEST';

export function getMbsMockingType(env: CommonEnvironmentData): string {
  if (env.mockMbsApi === 'local') {
    return env.mbsLhUrl;
  } else if (env.mockMbsApi === 'mock') {
    return MockApiKeyword;
  }
}
