export enum CITY_MOCK_TYPE {
  EMPTY,
  SINGLE,
  MULTI,
  SINGLE_CITY_MULTI_PRODUCT
}

export const MbsCityMocks: string[][] = [
  [
    /* EMPTY BY DESIGN */
  ],
  [
      'Leipzig'
  ],
  [
    'Leipzig',
    'Markranstädt',
    'Lützen'
  ],
  [
    'XYZ'
  ]
];
