  // tslint:disable:object-literal-key-quotes


/* Definitive edition of SAP product names */
export type SapProductsNamesList =
  'STROM_LPREIS_PRIVAT'
| 'STROM_LPREIS_PRIVAT_NK-2022'
| 'STROM_LPLUS_PRIVAT'
| 'STROM_GRUNDVERSORG_HH'
| 'STROM_LPREIS_PROFI'
| 'STROM_LPREIS_PROFI_NK-2022'
| 'STROM_GRUNDVERSORG_GW'
| 'STROM_BASISVERSORG_GW'
| 'GAS_LPREIS_PRIVAT'
| 'GAS_LPREIS_PRIVAT_NK-2022'
|  'GAS_LPLUS_PRIVAT'
|  'GAS_GRUNDVERSORG_HH'
|  'GAS_LPREIS_PROFI'
|  'GAS_LPREIS_PROFI_NK-2022'
|  'GAS_GRUNDVERSORG_GW'
|  'GAS_BASISVERSORG_GW';

export interface PridutInterfaceDefinition {
  name: string;
  description: string;
  image: string;
  mobileTabName: string;
  color: string;
  buttonText: string;
}

export const FallbackDefaultSapProduct: PridutInterfaceDefinition = {
  name: 'Fallback Product',
  description: 'This product presence is indicator of SAP product delivery error',
  image: '',
  mobileTabName: 'fallback',
  color: 'gray',
  buttonText: 'STOP'
};

export const productConfig:
  { [k in SapProductsNamesList]: PridutInterfaceDefinition } = {
  'STROM_LPREIS_PRIVAT': {
    name: 'L-Strom.bestpreis',
    description: ` Der Regional-Tarif<br>attraktive Vorteile & Extras`,
    image: '',
    mobileTabName: 'bestpreis',
    color: 'yellow-light',
    buttonText: 'Auswählen'
  },
  'STROM_LPREIS_PRIVAT_NK-2022': {
    name: 'L-Strom.bestpreis',
    description: ` Der Regional-Tarif attraktive Vorteile & Extras`,
    image: '/images/bestpreis.png',
    mobileTabName: 'bestpreis',
    color: 'yellow',
    buttonText: 'Auswählen'
  },
  'STROM_LPLUS_PRIVAT': {
    name: 'L-Strom.plus',
    description: 'Das Premium-Angebot mit attraktiven Vorteilen',
    image: '',
    mobileTabName: 'plus',
    color: 'yellow',
    buttonText: 'Auswählen'
  },
  'STROM_GRUNDVERSORG_HH': {
    name: 'L-Strom.basis (Grundversorgung)',
    description: 'Der Basis-Tarif<br>flexibel & sicher',
    image: '',
    mobileTabName: 'basis',
    color: 'gray',
    buttonText: 'Auswählen'
  },
  'STROM_LPREIS_PROFI': {
    name: 'L-Strom.bestpreis',
    description: ` Der Regional-Tarif<br>attraktive Vorteile & Extras`,
    image: '',
    mobileTabName: 'bestpreis',
    color: 'yellow-light',
    buttonText: 'Auswählen'
  },
  'STROM_LPREIS_PROFI_NK-2022': {
    name: 'L-Strom.bestpreis',
    description: 'Unser bester Preis zu günstigen Konditionen',
    image: '/images/bestpreis.png',
    mobileTabName: 'bestpreis',
    color: 'yellow',
    buttonText: 'Auswählen'
  },
  'STROM_GRUNDVERSORG_GW': {
    name: 'L-Strom.basis (Grundversorgung)',
    description: 'Der Basis-Tarif<br>flexibel & sicher',
    image: '',
    mobileTabName: 'basis',
    color: 'gray',
    buttonText: 'Auswählen'
  },
  'STROM_BASISVERSORG_GW': {
    name: 'Basisversorgung für Geschäftskunden',
    description: '',
    image: '',
    mobileTabName: 'basis',
    color: 'gray',
    buttonText: 'Auswählen'
  },
  'GAS_LPREIS_PRIVAT': {
    name: 'L-Gas.bestpreis',
    description: ` Der Regional-Tarif<br>attraktive Vorteile & Extras`,
    image: '',
    mobileTabName: 'bestpreis',
    color: 'yellow-light',
    buttonText: 'Auswählen'
  },
  'GAS_LPREIS_PRIVAT_NK-2022': {
    name: 'L-Gas.bestpreis',
    description: 'Unser bester Preis zu günstigen Konditionen',
    image: '/images/bestpreis.png',
    mobileTabName: 'bestpreis',
    color: 'yellow',
    buttonText: 'Auswählen'
  },
  'GAS_LPLUS_PRIVAT': {
    name: 'L-Gas.plus',
    description: 'Das Premium-Angebot mit attraktiven Vorteilen',
    image: '',
    mobileTabName: 'plus',
    color: 'yellow',
    buttonText: 'Auswählen'
  },
  'GAS_GRUNDVERSORG_HH': {
    name: 'L-Gas.basis (Grundversorgung)',
    description: 'Der Basis-Tarif<br>flexibel & sicher',
    image: '',
    mobileTabName: 'basis',
    color: 'gray',
    buttonText: 'Auswählen'
  },
  'GAS_LPREIS_PROFI': {
    name: 'L-Gas.bestpreis',
    description: ` Der Regional-Tarif<br>attraktive Vorteile & Extras`,
    image: '',
    mobileTabName: 'bestpreis',
    color: 'yellow-light',
    buttonText: 'Auswählen'
  },
  'GAS_LPREIS_PROFI_NK-2022': {
    name: 'L-Gas.bestpreis',
    description: 'Unser bester Preis zu günstigen Konditionen',
    image: '/images/bestpreis.png',
    mobileTabName: 'bestpreis',
    color: 'yellow',
    buttonText: 'Auswählen'
  },
  'GAS_GRUNDVERSORG_GW': {
    name: 'L-Gas.basis (Grundversorgung)',
    description: 'Der Basis-Tarif<br>flexibel & sicher',
    image: '',
    mobileTabName: 'basis',
    color: 'gray',
    buttonText: 'Auswählen'
  },
  'GAS_BASISVERSORG_GW': {
    name: 'Basisversorgung für Geschäftskunden',
    description: 'keine feste Vertragslaufzeit',
    image: '',
    mobileTabName: 'basis',
    color: 'gray',
    buttonText: 'Auswählen'
  }
};

export const titleImages = {
  power: {
    private: '/images/power.jpg',
    business: '/images/power.jpg'
  },
  gas: {
    private: '/images/gas.jpg',
    business: '/images/gas.jpg'
  }
};


export const allSapProducts: SapProductsNamesList[] = Object.keys(productConfig) as SapProductsNamesList[];

export const visibleBasicTariffs: SapProductsNamesList[] = [
  'STROM_GRUNDVERSORG_HH',
  'STROM_GRUNDVERSORG_GW',
  'STROM_BASISVERSORG_GW',
  'GAS_GRUNDVERSORG_HH',
  'GAS_GRUNDVERSORG_GW',
  'GAS_BASISVERSORG_GW',
];

export const tariffOrder: SapProductsNamesList[] = [
  'STROM_LPREIS_PRIVAT',
  'STROM_LPREIS_PRIVAT_NK-2022',
  'STROM_LPREIS_PROFI',
  'STROM_LPREIS_PROFI_NK-2022',
  'GAS_LPREIS_PRIVAT',
  'GAS_LPREIS_PRIVAT_NK-2022',
  'GAS_LPREIS_PROFI',
  'GAS_LPREIS_PROFI_NK-2022',
  'STROM_LPLUS_PRIVAT',
  'GAS_LPLUS_PRIVAT',
  'STROM_GRUNDVERSORG_HH',
  'STROM_GRUNDVERSORG_GW',
  'STROM_BASISVERSORG_GW',
  'GAS_GRUNDVERSORG_GW',
  'GAS_GRUNDVERSORG_HH',
  'GAS_BASISVERSORG_GW'
];

export const BlockSapProducts: SapProductsNamesList[] = [];
export const AllowAllSapProducts: SapProductsNamesList[] = undefined;

/* This will be used as SAP product filtering */
/* Empty array will bock all SAP products */
/* 'undefined' will pass all SAP products */
export const WhitelistFiltering: SapProductsNamesList[] = AllowAllSapProducts;

export const lowLoadTariffs = [];

export const productNumberFields = ['BasePriceGross', 'BasePriceGrossLt', 'GreenPriceGross', 'WorkingPriceGross', 'OnlinePPriceGross',
  'Bonus', 'Bonus02PriceGross', 'Bonus01PriceGross', 'YearlyPrice' , 'MonthlyPrice', 'YearlyPriceWithBonus', 'Consumption',
  'ConsumptionLt'];


export const productDateFields = ['ValidFrom', 'PriceLevel', 'PriceGuaranteeDate'];

export const baseProductPricesheetUrls = {
  POWER: 'https://www.L.de/download-stadtwerke-preisblatt-strom-gv-bv',
  GAS: 'https://www.L.de/download-stadtwerke-preisblatt-gas-gv-bv',
};

export const DisableSapProductSearch = true;
