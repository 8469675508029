import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { DateSimpleFormat } from '../_models/mbs/acquisitionHelper';
import * as moment from 'moment';
import { FeatureTypeIfc } from '../_models/interface';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class MbsProductService {

  constructor(private http: HttpClient) {}

  public loadRegioDelivery(baseUrl: string, startDate: moment.Moment): Observable<FeatureTypeIfc> {
    const startDateString = startDate.format(DateSimpleFormat);
    const url = `${baseUrl}/feature-flag/regio-delivery?deliveryDate=${startDateString}`;

    const headers = new HttpHeaders()
    .set('loadingIndicator', 'none');

    return this.http.get<FeatureTypeIfc>(url, { headers }).pipe(
      catchError((error: HttpErrorResponse) => {
        console.log(error);
        return of({
          enabled: false,
          name: '',
        });
      })
    );
  }
}
