import { BREAKPOINT } from '@angular/flex-layout';

export const CUSTOM_BREAKPOINTS = [
  {
    alias: 'products.lt-md',
    suffix: 'products.lt-md',
    mediaQuery: 'screen and (max-width: 640px)',
    overlapping: false,
    priority: 1001
  },
  {
    alias: 'products.sm',
    suffix: 'products.sm',
    mediaQuery: 'screen and (min-width: 401px) and (max-width: 1000px)',
    overlapping: false,
    priority: 1001
  },
  {
    alias: 'products.xs',
    suffix: 'products.xs',
    mediaQuery: 'screen and (max-width: 400px)',
    overlapping: false,
    priority: 1003
  }
];

export const CustomBreakPointsProvider = {
  provide: BREAKPOINT,
  useValue: CUSTOM_BREAKPOINTS,
  multi: true
};
