import { NgModule } from '@angular/core';
import { SearchFormComponent } from './search-form.component';
import { SharedModule } from '../../shared.module';
import {
  LswAlertModule,
  LswAutocompleteModule,
  LswButtonModule,
  LswDatepickerModule,
  LswInputModule,
  LswRadioModule,
  LswSelectModule,
  LswSlideToggleModule,
  LswTabsModule
} from '@ui/lsw-lib';

@NgModule({
  declarations: [SearchFormComponent],
  imports: [
    SharedModule,
    LswSelectModule,
    LswAutocompleteModule,
    LswDatepickerModule,
    LswInputModule,
    LswAlertModule,
    LswButtonModule,
    LswSlideToggleModule,
    LswTabsModule,
    LswRadioModule
  ],
  exports: [SearchFormComponent]
})
export class SearchFormModule { }
