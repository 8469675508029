import { Observable } from 'rxjs';
import { OrderPayloadIfc, SECTION_ENUM } from '../../_models/mbs/payload.mbs.types';
import { ApiProvidersIfc } from '../../_models/mbs/step2.mbs.types';
import { CreditScoringBusinessPayloadIfc, CreditScoringIfcResult, CreditScoringPrivatePayloadIfc } from '../../_models/mbs/transition.msb.types';
import { MbsProductHttp } from '../../_models/mbsProduct';
import { SectorTypeEnum, SimplifiedHttpResponse } from '../../_models/enum';

export abstract class ApiIfc {
  abstract getStreets(
    url: string,
    postalCode: string,
    city: string
  ): Observable<string[]>;
  abstract finishAcquisition(
    url: string,
    pld: OrderPayloadIfc
  ): Observable<SimplifiedHttpResponse>;
  abstract validatePostalCode(
    url: string,
    postalCode: string
  ): Observable<string[]>;
  abstract getProductByBasicInfo(
    url: string,
    postalCode: string,
    demand: string,
    section: SECTION_ENUM,
    isPrivateCustomer: boolean,
    sector: SectorTypeEnum,
    startDate: string
  ): Observable<MbsProductHttp>;
  abstract getProduct(
    url: string,
    postalCode: string,
    demand: string,
    city: string,
    street: string,
    houseNo: string,
    section: SECTION_ENUM,
    isPrivateCustomer: boolean,
    sector: SectorTypeEnum,
    startDate: string
  ): Observable<MbsProductHttp>;
  abstract getProviders(
    url: string,
    section: SECTION_ENUM
  ): Observable<ApiProvidersIfc[]>;
  abstract userExisting(url: string, userName: string): Observable<boolean>;
  abstract getCreditScoringForBusiness(
    url: string,
    scoring: CreditScoringBusinessPayloadIfc
  ): Observable<CreditScoringIfcResult>;
  abstract getCreditScoringForPrivate(
    url: string,
    scoring: CreditScoringPrivatePayloadIfc
  ): Observable<CreditScoringIfcResult>;
  abstract checkIfUserCanBeRegistered(
    url: string,
    businessPartnerNumber: string,
    email: string
  ): Observable<any>;
}
