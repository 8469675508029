<div class="search-container">
  <p
    class="unsuported-zip-code-warning"
    *ngIf="showUnsupportedZipCodeWarning"
  >
    In Ihrem Postleitzahlengebiet können wir Ihnen leider kein Angebot
    unterbreiten.
  </p>
  <div
    *ngIf="!((productsFilled$ | async) && showPrefilledString)"
    fxLayout="row"
    fxLayoutAlign="center"
    [ngClass]="{
      'search-bg': true,
      large:
        !mediaObserver.isActive('products.xs') &&
        !mediaObserver.isActive('products.sm')
    }"
  >
    <lsw-tabs
      [tabsLabels]="tabsLabels"
      [displayMode]="'PROJECTION'"
      (IndexChange)="onTabClick($event)"
      [selectedIndex]="tabSelection$ | async"
    >
      <ng-template lswTabsProjectionContent>
        <div class="search-container">
          <div
            *ngIf="(hasError | async) || (productsError$ | async)"
            class="error-container"
            fxLayout="row"
            fxLayoutAlign="flex-start"
          >
            <div
              class="error-elements-container"
              fxLayout="row wrap"
              fxLayoutAlign="flex-start"
            >
              <lsw-alert
                variant="ERROR"
                [message]="'SEARCHFORM.ERROR.MESSAGE' | translate"
              >
              </lsw-alert>
            </div>
          </div>

          <div
            *ngIf="
              (showStreetAlert$ | async) &&
              !((productsFilled$ | async) && showPrefilledString)
            "
            class="error-container"
            fxLayout="row"
            fxLayoutAlign="flex-start"
          >
            <div
              class="error-elements-container"
              fxLayout="row wrap"
              fxLayoutAlign="flex-start"
            >
              <lsw-alert
                variant="ERROR"
                [message]="'SEARCHFORM.ZIPCODE.MESSAGE' | translate"
              ></lsw-alert>
            </div>
          </div>

          <div *ngIf="(tabSelection | async) === tabIndexEnum.HEATPUMP" class="heatpump-info" [innerHTML]="'HEATPUMP.HEATPUMPINFO' | translate"></div>

          <div
            *ngIf="!((productsFilled$ | async) && showPrefilledString)"
            class="search-form-container"
            fxLayout="row"
            fxLayoutAlign="flex-start"
          >
            <form
              [formGroup]="searchForm"
              (ngSubmit)="onSubmit()"
              fxLayout="row wrap"
              fxLayoutGap="10px"
              fxLayoutAlign="flex-start"
              class="search-from"
              ngClass.xs="small"
            >
              <div class="customerTypeSwitchContainer" fxFlex="0 1 100">
                <lsw-slide-toggle
                  *ngIf="showCustomerSelect"
                  [control]="control('customerTypeSwitch')"
                  [variant]="'DOUBLE'"
                  [textBefore]="'SEARCHFORM.PRIVATE' | translate"
                  [textAfter]="'SEARCHFORM.BUSINESS' | translate"
                ></lsw-slide-toggle>
              </div>

              <form
                [formGroup]="heatPumpForm"
                class="heatpump"
                *ngIf="(tabSelection | async) === tabIndexEnum.HEATPUMP"
              >
                <div class="heatpump__question">
                  <p>
                    {{ 'HEATPUMP.MAINQUESTION.QUESTION' | translate }}
                  </p>
                  <div class="heatpump__radios">
                    <lsw-radio
                      [options]="heatPumpOptions"
                      [control]="mainQuestionControl"
                      [variant]="'COLUMN'"
                      style="font-size: 0.8em !important"
                    ></lsw-radio>
                  </div>
                  <ng-container *ngIf="mainQuestionControl.value === false">
                    <p>{{ 'HEATPUMP.MAINQUESTION.NOANSWER' | translate }}</p>
                  </ng-container>
                </div>
                <div class="heatpump__question"
                  *ngIf="mainQuestionControl?.value === true">
                  <p>
                    {{ 'HEATPUMP.FIRSTQUESTION.QUESTION1' | translate }}
                    <a [href]="heatPumpPrivilegeLink" target="_blank">
                      {{ 'HEATPUMP.FIRSTQUESTION.LINK' | translate }}</a
                    >
                    {{ 'HEATPUMP.FIRSTQUESTION.QUESTION2' | translate }}
                  </p>
                  <div class="heatpump__radios">
                    <lsw-radio
                      [options]="heatPumpOptions"
                      [control]="firstQuestionControl"
                      [variant]="'COLUMN'"
                      style="font-size: 0.8em !important"
                    ></lsw-radio>
                  </div>
                  <ng-container *ngIf="firstQuestionControl.value === false">
                    <p>{{ 'HEATPUMP.FIRSTQUESTION.NOANSWER' | translate }}</p>
                  </ng-container>
                </div>
                <div class="heatpump__question"
                  *ngIf="firstQuestionControl?.value === true"
                >
                  <p>
                    {{ 'HEATPUMP.SECONDQUESTION.QUESTION' | translate }}
                    <a [href]="heatPumpConsumptionLink" target="_blank">
                      {{ 'HEATPUMP.SECONDQUESTION.LINK' | translate }}</a
                    >?
                  </p>
                  <div class="heatpump__radios">
                    <lsw-radio
                      [options]="heatPumpOptions"
                      [control]="secondQuestionControl"
                      [variant]="'COLUMN'"
                      style="font-size: 0.8em !important"
                    ></lsw-radio>
                  </div>
                </div>
              </form>

              <div class="dynamic-power" *ngIf="(tabSelection | async) === tabIndexEnum.DYNAMIC">
                <div class="dynamic-power__text">
                  <span>{{ 'DYNAMICPOWER.QUESTION' | translate }}</span>
                  <div [tooltip]="'DYNAMICPOWER.TOOLTIP' | translate" >
                    <img [src]="sharedConfig.assetPath + '/images/info.svg'" alt="info-icon" width="18px" height="18px"/>
                  </div>
                </div>
                  <div class="dynamic-power__control">
                    <lsw-radio [options]="dynamicPowerOptions" [control]="dynamicPowerControl"></lsw-radio>
                  </div>
                  <p class="dynamic-power__info" *ngIf="dynamicPowerControl.value === false">
                    {{ 'DYNAMICPOWER.NOANSWER' | translate }}
                  </p>
              </div>

              <div
                class="controlContainer"
                *ngIf="(shouldSubmitBeVisible$ | async) || ((tabSelection | async) === tabIndexEnum.DYNAMIC && dynamicPowerControl.value)"
              >
                <div class="flexContainer">
                  <lsw-autocomplete
                    *ngIf="testRender && (showConsumptionDropdown$ | async)"
                    class="search-from-element"
                    [lswDigitsOnly]="true"
                    [placeholder]="'SEARCHFORM.CONSUMPTION.LABEL' | translate"
                    [control]="control('consumption')"
                    [options]="consumptionOptions"
                    [label]="'SEARCHFORM.CONSUMPTION.FLOATING' | translate"
                    (keyup)="validateConsumption()"
                    floatLabel="'auto'"
                    [hideRequiredMarker]="true"
                  ></lsw-autocomplete>

                  <lsw-input
                    *ngIf="(showConsumptionDropdown$ | async) === false"
                    type="text"
                    maxLength="20"
                    (keyup)="validateConsumption()"
                    class="search-from-element"
                    [lswDigitsOnly]="true"
                    [control]="control('consumption')"
                    [label]="'SEARCHFORM.CONSUMPTION.FLOATING' | translate"
                    floatLabel="'auto'"
                  >
                  </lsw-input>
                </div>
                <div class="flexContainer shorter-input">
                  <lsw-input
                    type="search"
                    maxLength="5"
                    class="search-from-element search-custom-input"
                    [lswDigitsOnly]="true"
                    [control]="control('zipCode')"
                    [label]="'SEARCHFORM.ZIPCODE.FLOATING' | translate"
                    floatLabel="'auto'"
                  >
                    <span
                      *ngIf="
                        control('zipCode').touched &&
                        control('zipCode').dirty &&
                        control('zipCode')?.errors?.noCity
                      "
                    >
                      {{ control('zipCode')?.errors.noCity }}
                    </span>
                  </lsw-input>
                </div>

                <div *ngIf="showDate" class="flexContainer">
                  <lsw-datepicker
                    (keydown)="preventNonNumericKeysForDate($event)"
                    class="search-from-element"
                    [min]="conf.minMoveInDate"
                    [max]="conf.maxMoveInDate"
                    [control]="control('moveInDate')"
                    twoDigitYearParseStrategy="PRESENT"
                    [label]="'SEARCHFORM.MOVEINDATE.FLOATING' | translate"
                    floatLabel="'auto'"
                  ></lsw-datepicker>
                </div>

                <div *ngIf="extraControls$ | async" class="flexContainer">
                  <lsw-select
                    class="search-from-element"
                    [border]="(showStreetAlert$ | async) ? 'MARKED' : 'NONE'"
                    [control]="control('city')"
                    [options]="cityOptions$ | async"
                    [label]="'SEARCHFORM.CITY.FLOATING' | translate"
                    floatLabel="'auto'"
                    [hideRequiredMarker]="true"
                  ></lsw-select>
                </div>

                <div *ngIf="extraControls$ | async" class="flexContainer">
                  <lsw-autocomplete
                  class="search-from-element required-default-color"
                  [fetchFn]="streetFilteringFn()"
                  [placeholder]="'SEARCHFORM.STREET.FLOATING' | translate"
                  [control]="control('street')"
                  [options]="streetOptions"
                  [label]="'SEARCHFORM.STREET.FLOATING' | translate"
                  floatLabel="'auto'"
                  [hideRequiredMarker]="true"
                  [hideArrow]="true"
                  (blurEvent)="onStreetSelectBlur()"
                  ></lsw-autocomplete>
                </div>

                <div
                  *ngIf="extraControls$ | async"
                  class="flexContainer shorter-input"
                >
                  <lsw-input
                    type="text"
                    class="search-from-element required-default-color"
                    [control]="control('houseNo')"
                    [label]="'SEARCHFORM.HOUSENUMBER.FLOATING' | translate"
                    floatLabel="'auto'"
                  >
                    <div
                      *ngIf="
                        control('houseNo').touched &&
                        control('houseNo').errors?.pattern
                      "
                      class="label"
                    >
                      Die Hnr.-Ergänzung können sie später eingeben.
                    </div>
                  </lsw-input>
                </div>

                <div class="flexContainer">
                  <lsw-button
                    class="submit-button search-from-element"
                    fxFlexAlign="flex-end"
                    type="submit"
                    [disabled]="shouldSubmitBeDisabled()"
                    variant="QUINARY"
                    radiusVariant="ROUNDED"
                    [isLoading]="loaderService.isLoading | async"
                    loadSpinner="DOTS"
                    loadPosition="BEFORE"
                    [title]="'SEARCHFORM.SUBMIT' | translate"
                  ></lsw-button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </ng-template>
    </lsw-tabs>
  </div>

  <div
    *ngIf="(productsFilled$ | async) && showPrefilledString"
    class="prefilled-container"
    fxLayout="row"
    fxLayoutAlign="center"
  >
    <div
      class="prefilled-elements-container"
      fxLayout="column wrap"
      fxLayout.gt-xs="row"
      fxLayoutGap="10px"
      fxLayoutAlign="space-between center"
    >
      <div
        class="prefilled-text"
        [innerHTML]="searchFormService.prefilledText$ | async"
      ></div>

      <lsw-button
        (click)="hidePrefilled = false; trackSearchFormView()"
        fxFlexAlign="flex-end"
        fxFlexAlign.gt-xs="center"
        class="lsw-button"
        variant="TERTIARY"
        radiusVariant="ROUNDED"
        [title]="'SEARCHFORM.CALCULATE' | translate"
      ></lsw-button>
    </div>
  </div>

  <div
    *ngIf="showNoProductMessage"
    class="message-wrapper"
    fxLayout="row"
    fxLayoutAlign="center"
  >
    <div class="no-results-label" ngClass.xs="small">
      <p>{{ 'SEARCHFORM.NORESULTS' | translate }}&hellip;</p>
    </div>
  </div>
</div>