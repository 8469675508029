export type mbsUrlMockType = 'none' | 'local' | 'mock';

export const MbsApiConfig: mbsUrlMockType = 'mock';


export interface CommonEnvironmentData {
  production: boolean;
  mockMbsApi: mbsUrlMockType;
  mbsLhUrl?: 'http://localhost:8220/api/vertragsabschluss-backend';
  [propName: string]: any;
}
