import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DateService {
  getSelectableMoveInDateRange() {
    const minMoveInDate = new Date();
    minMoveInDate.setDate(minMoveInDate.getDate() - 6 * 7);
    const maxMoveInDate = new Date();
    maxMoveInDate.setMonth(maxMoveInDate.getMonth() + 6);
    return {minMoveInDate, maxMoveInDate};
  }
}
