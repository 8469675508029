import { Component } from '@angular/core';
import { maintenanceMessage } from '../../../../../settings/app.settings';

@Component({
  selector: 'lib-maintenance-block',
  templateUrl: './maintenance-block.component.html',
  styleUrls: ['./maintenance-block.component.scss'],
  standalone: true,
})
export class MaintenanceBlockComponent {
  maintenanceMessage = maintenanceMessage
}
