
export const LiepzigStreetsMock: string[] = [
  'LiepzigStr 1',
  'LiepzigStr 2',
  'LiepzigStr 3',
  'LiepzigStr 4',
  'LiepzigStr 5',
];

export const LutzenStreetsMock: string[] = [
  'Lützen street 1',
  'Lützen street 2',
  'Lützen street 3',
  'Lützen street 4',
  'Lützen street 5',
];

export const MarkranstadtStreetsMock: string[] = [
  'Markranstädt Street 1',
  'Markranstädt Street 2',
  'Markranstädt Street 3',
  'Markranstädt Street 4',
  'Markranstädt Street 5',
];
