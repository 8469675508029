import { Injectable } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
  HttpStatusCode
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { ErrorService } from '../services/error.service';
import { LoaderService } from '../services/loader.service';
import { AppMode } from '@ekso/ekso-types';
import { AppSettingsService } from '../../../../settings/app-settings.service';
@Injectable()
export class RequestInterceptor implements HttpInterceptor {
  private requests: HttpRequest<any>[] = [];

  constructor(private loaderService: LoaderService, private errorService: ErrorService) {
  }

  removeRequest(req: HttpRequest<any>) {
    const i = this.requests.indexOf(req);
    if (i !== -1 ) {
      this.requests.splice(i, 1);
    }
    this.loaderService.isLoading.next(this.requests.length > 0);
  }

  private setMaintenanceModeOn() {
    AppSettingsService.appMode = AppMode.MAINTENANCE;
    AppSettingsService.setMaintenanceMode(true);
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.errorService.hide();
    if (!req.headers.getAll('loadingIndicator')) {
      this.requests.push(req);
      this.loaderService.isLoading.next(true);
    }
    return new Observable(observer => {
      const subscription = next.handle(req)
        .subscribe(
          event => {
            if (event instanceof HttpResponse) {
              this.removeRequest(req);
              observer.next(event);
            }
          },
          (err: HttpErrorResponse) => {
            this.removeRequest(req);
            observer.error(err);
            this.errorService.show();
            if (err.status === HttpStatusCode.ServiceUnavailable) {
              this.setMaintenanceModeOn();
            }
          },
          () => { this.removeRequest(req); observer.complete(); });
      // teardown logic in case of cancelled requests
      return () => {
        this.removeRequest(req);
        subscription.unsubscribe();
      };
    });
  }

}
