import { EmailCommunicationType } from './step1.mbs.types';
import {
  RESIDENCE_TIME,
  CONTRACT_ENDING_TYPE,
  CURRENT_CONTRACT_PORTAL_END_DATE_TYPE,
} from './step2.mbs.types';
import { PAYMENT_METHOD } from './step3.mbs.types';
import { SectorTypeEnum } from '../enum';
import { MbsOldSupplierCancellationState, MbsSupplyChangeCause } from '@ekso/ekso-types';

export enum GENDER_ENUM {
  FEMALE = 1,
  MALE,
  OTHER,
}

export interface ContractPersonalData {
  name: string;
  surname: string;
  birthDate: string;
  email: string;
}

export interface AddressData {
  city: string;
  postalCode: string;
  street: string;
  housenumber: string;
}

export type PaymentType = 'SEPA' | 'transfer' | 'payPal' | 'card';

export interface ApiPayloadPersonalDataCustomer {
  firstname: string;
  lastname: string;
  company?: string;
  nameaddition?: string;
  salutation?: string;
  legalForm: string;
}

export interface ApiPayloadCustomer extends ApiPayloadPersonalDataCustomer {
  email: string;
  password: string;
  emailCommunication: {
    channelAddress: string;
    lastAdvertisingUsage?: string;
    optInDate?: string;
    optInOriginalWording?: string;
  };
  phoneNumber: string;
  phoneCommunication: {
    channelAddress: string;
    lastAdvertisingUsage?: string;
    optInDate?: string;
    optInOriginalWording?: string;
  };
  dateOfBirth: string;
  businessPartnerNumber: string;
}

export interface ApiPayloadBankAccountOwner {
  firstname: string;
  lastname: string;
  company: string;
  nameaddition: string;
  dateOfBirth?: string;
  email?: string;
  emailCommunication?: {
    channelAddress: string;
    lastAdvertisingUsage?: string;
    optInDate?: string;
    optInOriginalWording?: string;
  };
}

export enum SECTION_ENUM {
  STROM = 'Electricity',
  GAS = 'Gas',
}

export interface PayloadAddons {
  paymentType: PAYMENT_METHOD;
  residenceTime: RESIDENCE_TIME;
  contractEndingState?: CONTRACT_ENDING_TYPE;
  contractEndingDate?: CURRENT_CONTRACT_PORTAL_END_DATE_TYPE;
  allDataProvided: boolean;
  section: SECTION_ENUM;
  acquisitionId: string;
}

export interface OrderPayloadIfc {
  addOns: PayloadAddons;

  /* Banking Data */
  bankAccountIban?: string;
  bankAccountOwner?: ApiPayloadBankAccountOwner;
  bankAccountOwnerName?: string;

  /* Main Customer Data */
  customer: ApiPayloadCustomer;
  ratingScore: number;
  deliveryPointAddress: AddressData;

  additionalCustomer?: ApiPayloadPersonalDataCustomer;

  businessConditionsAccepted: boolean;
  section: SECTION_ENUM;
  deviatingCustomer?: {
    firstname: string;
    lastname: string;
    salutation?: string;
    legalForm: string;
  };
  deviatingCustomerAddress?: AddressData;

  consumptionPrognosisKwh: string;
  deliveryPointMeteringDeviceNumber: string;
  marketLocation: string;
  oldSupplier: string;

  oldSupplierCancellationState?: MbsOldSupplierCancellationState;

  supplyBeginDate?: string;
  supplyChangeCause?: MbsSupplyChangeCause;
  supplierOldCancellationDate?: string;

  tarifId: number;
  tarifName: string;
  customerSignatureDate: string /*"2020-07-23T14:29:04.822Z" */;
  contractConclusionDate: string /* "2020-07-23T14:29:04.822Z" */;
  contractDate: string;

  communicationType: EmailCommunicationType;
  email?: string;
  sectorType: SectorTypeEnum;
}
