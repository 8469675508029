import { ApiProvidersIfc } from '../step2.mbs.types';

export const MbsProvidersMock: ApiProvidersIfc[] = [
  {
    iln: '1',
    name: 'A-provider'
  },
  {
    iln: '2',
    name: 'A-provider'
  },
  {
    iln: '3',
    name: 'B-provider'
  },
  {
    iln: '4',
    name: 'C-provider'
  },
  {
    iln: '5',
    name: 'A-proABCvider'
  },
  {
    iln: '6',
    name: 'A-provider'
  },
  {
    iln: '7',
    name: 'B-providABCer'
  },
  {
    iln: '8',
    name: 'C-provider'
  },
  {
    iln: '9',
    name: 'ABC-provider'
  },
  {
    iln: '10',
    name: 'A-provider'
  },
  {
    iln: '11',
    name: 'B-provider'
  },
  {
    iln: '12',
    name: 'C-provider'
  },
];
