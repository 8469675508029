import { Injectable } from '@angular/core';
import { SearchValues } from '../../_models/interface';
import { MbsProduct } from '../../_models/mbsProduct';

declare const etCommerce: any;
declare const etForm: any;
declare const _etracker: any;
declare const et_eC_Wrapper: any;
declare const et_et: any;

@Injectable({
  providedIn: 'root'
})
export class EtrackerService {
  static trackCommerce(key, data, quantity = 0): void {
    if (typeof (etCommerce) === 'object') {
      if (quantity) {
        etCommerce.sendEvent(key, data, quantity);
      } else {
        etCommerce.sendEvent(key, data);
      }
    }
  }

  static trackForm(key, name): void {
    if (typeof (etForm) === 'object') {
      etForm.sendEvent(key, name);
    }
  }

  trackPageLoad(): void {
    setTimeout(() => {
      if (typeof (_etracker) === 'object' && typeof (et_et) === 'string') {
        et_eC_Wrapper({
          et_et,
          et_pagename: document.title
        });
      }
    }, 0);
  }

  createProductTackingObject(product, params: SearchValues, trackOptions = false, trackOptionCategory = false) {
    const mproduct = {
      id: product.ProductID,
      name: product.name,
      category: [
        params.customerType === 'PRIVATE' ? 'privat' : 'gewerbe',
        params.sectorType === 'POWER' ? 'Strom' : 'Gas',
        trackOptionCategory ? this.getOptionCategory(product.OnlineP, product.GreenEnergy) : '',
        params.zipCode
      ],
      price: product.MonthlyPrice,
      currency: 'EUR'
    };
    if (trackOptions) {
      const variants = {
        productOnline: product.OnlineP ? '1' : '0',
        greenEnergies: product.GreenEnergy ? '1' : '0'
      };
      Object.assign(mproduct, {variants});
    }
    return mproduct;
  }

  createProductTackingObjectMbs(product: MbsProduct, params: SearchValues, trackOptions = false, trackOptionCategory = false) {
    const mproduct = {
      id: product.getProductId(),
      name: product.getProductName(),
      category: [
        params.customerType === 'PRIVATE' ? 'privat' : 'gewerbe',
        params.sectorType === 'POWER' ? 'Strom' : 'Gas',
        trackOptionCategory ? this.getOptionCategory(true, true) : '',
        params.zipCode
      ],
      price: product.getPriceYearly(),
      currency: 'EUR'
    };
    if (trackOptions) {
      const variants = {
        productOnline: '1',
        greenEnergies: '1'
      };
      Object.assign(mproduct, {variants});
    }
    return mproduct;
  }

  getOptionCategory(onlineP, greenEnergy) {
    let category = 'keine Option';
    if (onlineP && greenEnergy) {
      category = 'Ökooption + Onlineoption';
    } else if (onlineP) {
      category = 'Onlineoption';
    } else if (greenEnergy) {
      category = 'Ökooption';
    }
    return category;
  }
}
