import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';
@Injectable()
export class ErrorService {
  constructor(private router: Router) {
    this.router.events.subscribe(() => this.hide());
  }

  hasError = new Subject<boolean>();
  show() {
    this.hasError.next(true);
  }
  hide() {
    this.hasError.next(false);
  }
}
