import { Injectable } from '@angular/core';
import { Product } from '../_models/interface';

@Injectable({
  providedIn: 'root'
})
export class ProductService {

  public isBasicSupply(p: Product): boolean {
    return p.BasicSupplyTariff;
  }
  
  public isBestPrice(p: Product): boolean {
    return p.PriceGuarantees.results.length === 0;
  }

  public isPowerProduct(p: Product): boolean {
    return p.ProductID.includes('STROM');
  }

  public isPlusProduct(p: Product): boolean {
    return p.ProductID.includes('LPLUS');
  }

  public isPriceProduct(p: Product): boolean {
    return p.ProductID.includes('LPREIS');
  }

  public hasLowLoadRegulation(p: Product): boolean {
    return p.UseLt;
  }

  public onlineOption(p: Product): boolean {
    return p.OnlineP;
  }

  public ecoOption(p: Product): boolean {
    return p.GreenEnergy;
  }

  public instanceOfProduct(object: any): object is Product {
    return 'name' in object &&
      'color' in object &&
    'image' in object &&
    'name' in object &&
    'mobileTabName' in object &&
    'description' in object &&
    'buttonText' in object &&
    'GreenEnergy' in object &&
    'Runtime' in object &&
    'PriceGuaranteeDate' in object &&
    'ValidFrom' in object &&
    'PriceLevel' in object &&
    'YearlyPriceWithBonus' in object &&
    'YearlyPrice' in object &&
    'BasePriceGross' in object &&
    'BasePriceGrossLt' in object &&
    'WorkingPriceGross' in object &&
    'WorkingPriceGrossLt' in object &&
    'Bonus' in object &&
    'GreenPriceGross' in object &&
    'OnlinePPriceGross' in object &&
    'Bonus02PriceGross' in object &&
    'Bonus02Text' in object &&
    'Bonus01PriceGross' in object &&
    'Bonus01Text' in object &&
    'MonthlyPrice' in object &&
    'ProductID' in object &&
    'BpKindID' in object &&
    'DivisionID' in object &&
    'Runtimes' in object &&
    'PriceGuarantees' in object &&
    'GreenEnergies' in object &&
    'ServiceProviderName' in object &&
    'StartDate' in object &&
    'ProductCharacteristics' in object &&
    'ProductOnlines' in object;
  }
}
