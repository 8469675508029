import { Directive } from '@angular/core';
import { ClassDirective } from '@angular/flex-layout';

const selector = `[ngClass.products.sm], [ngClass.products.xs]`;
const inputs = ['ngClass.products.sm', 'ngClass.products.xs'];

@Directive({selector, inputs})
export class CustomClassDirective extends ClassDirective {
  protected inputs = inputs;
}
