import { AppState } from './app.state';
import { createSelector } from '@ngrx/store';
import { QUERY_LEVEL } from '../_models/interface';
import { MbsProduct } from '../_models/mbsProduct';

export enum ProductType {
  MBS = 'mbs',
  SAP = 'sap',
}

export interface AppStates {
  lswContractClosing: AppState;
}

export const selectLswContract = (state: AppStates) => state.lswContractClosing;

export const getState = createSelector(
  selectLswContract,
  (state: AppState) => state
);
export const getSearchValues = createSelector(
  selectLswContract,
  (state: AppState) => state.searchValues
);
export const getHeatPumpValues = createSelector(
  selectLswContract,
  (state: AppState) => state.heatPumpValues
);
export const getStreets = createSelector(
  selectLswContract,
  (state: AppState) => state.streets
);
export const getCities = createSelector(
  selectLswContract,
  (state: AppState) => state.cities
);
export const getStreetAlert = createSelector(
  selectLswContract,
  (state: AppState) => state.showStreetAlert
);
export const getProductsError = createSelector(
  selectLswContract,
  (state: AppState) => state.productMappingError
);
export const showExtraControls = createSelector(
  selectLswContract,
  (state: AppState) => {
    return ((state.queryLevels.mbs.level > QUERY_LEVEL.BASIC_SEARCH &&
      state.queryLevels.mbs.level < QUERY_LEVEL.SEARCH_DRAIN)
      ||
      (state.queryLevels.sap.level > QUERY_LEVEL.BASIC_SEARCH &&
        state.queryLevels.sap.level < QUERY_LEVEL.SEARCH_DRAIN)) && (state.streets.length > 0 && state.cities.length > 0);
  }
);
export const productsFilled = createSelector(
  selectLswContract,
  (state: AppState) => (((state.products?.length > 0) || state.mbsProducts?.length > 0))
);
export const productsEmpty = createSelector(
  selectLswContract,
  (state: AppState) => ((state.products !== null && state.products?.length === 0) &&
    (state.mbsProducts !== null && state.mbsProducts?.length === 0))
);
export const getProducts = createSelector(
  selectLswContract,
  (state: AppState) => {
    return {
      products: state.products,
      mbsProducts: state.mbsProducts ?
        state.mbsProducts.map(p => new MbsProduct(
          state.searchValues.sectorType,
          {
            expenses:  undefined,
            isSufficientInformationProvided: p.isSufficientInformationProvided,
            priceInformation: p.apiIfc
          },
          p.activeRuntimeMonths,
          state.heatPumpValues?.tariffId)) : state.mbsProducts
    };
  }
);

export const getSapProducts = createSelector(
  selectLswContract,
  (state: AppState) => {
    return {
      products: state.products,
    };
  }
);

export const getMbsProducts = createSelector(
  selectLswContract,
  (state: AppState) => {
    return {
      mbsProducts: state.mbsProducts ?
        state.mbsProducts.map(p => new MbsProduct(
          state.searchValues.sectorType,
          {
            expenses: undefined,
            isSufficientInformationProvided: p.isSufficientInformationProvided,
            priceInformation: p.apiIfc
          },
          p.activeRuntimeMonths,
          state.heatPumpValues?.tariffId)) : state.mbsProducts
    };
  }
);

export const getTitleImage = createSelector(
  selectLswContract,
  (state: AppState, props) => props.images[state.searchValues.sectorType.toLowerCase()][state.searchValues.customerType.toLocaleLowerCase()]
);
export const getSelectedProduct = createSelector(
  selectLswContract,
  (state: AppState) => state.selectedProduct
);
export const getSelectedMbsProduct = createSelector(
  selectLswContract,
  (state: AppState) => (
    state.selectedMbsProduct !== null ?
      new MbsProduct(
        state.searchValues.sectorType,
        {
          priceInformation: state.selectedMbsProduct.apiIfc,
          isSufficientInformationProvided: state.selectedMbsProduct.isSufficientInformationProvided,
          expenses: undefined
        },
        (state.selectedMbsProduct as MbsProduct).activeRuntimeMonths,
        state.heatPumpValues?.tariffId) :
      null
  )
);
export const getSelectedProductType = createSelector(
  selectLswContract,
  (state: AppState) => state.selectedProduct === null ? ProductType.MBS : ProductType.SAP
);
export const selectedProductHasOnlineOption = createSelector(
  selectLswContract,
  (state: AppState) => state.selectedProduct.ProductOnlines.results.length > 0
);
export const selectedProductHasEcoOption = createSelector(
  selectLswContract,
  (state: AppState) => state.selectedProduct.GreenEnergies.results.length > 0
);
export const getSectorType = createSelector(
  selectLswContract,
  (state: AppState) => state.searchValues.sectorType
);
export const getCustomerType = createSelector(
  selectLswContract,
  (state: AppState) => state.searchValues.customerType
);
export const getProductDetailInformations = createSelector(
  selectLswContract,
  (state: AppState) => {
    return {
      selectedProduct: state.selectedProduct,
      selectedMbsProduct: state.selectedMbsProduct,
      searchValues: state.searchValues
    };
  }
);
export const productSearchIsComplete = createSelector(
  selectLswContract,
  (state: AppState) => {
    return (state.products.length !== 0 || state.mbsProducts.length !== 0) &&
      state.searchValues !== null;
  }
);

export const queryLevels = createSelector(
  selectLswContract,
  (state: AppState) => state.queryLevels
);
