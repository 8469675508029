import { createReducer, on } from '@ngrx/store';
import {
  hideStreetAlert,
  searchProducts,
  setProducts,
  setAdditionalSearchFormData,
  updateSearchValues,
  updateHeatPumpValues,
  resetStreet,
  resetProducts,
  resetState,
  selectProduct,
  updateSearchDate,
  updateProducts,
  setProductsError,
  resetProductsError,
  resetSelectProduct,
  resetCity,
  resetQueryLevels,
  updateProductsWithPatch,
  selectMbsProduct,
  updateMbsProduct,
  updateDynamicValue,
} from './app.actions';
import { AppState } from './app.state';
import { QUERY_LEVEL } from '../_models/interface';
import { MbsProduct } from '../_models/mbsProduct';

export const initialState: AppState = {
  selectedProduct: null,
  selectedMbsProduct: null,
  products: null,
  mbsProducts: null,
  searchValues: null,
  heatPumpValues: null,
  dynamicTariffValue: null,
  streets: [],
  showStreetAlert: false,
  productMappingError: false,
  cities: [],
  queryLevels: {
    mbs: {
      drain: false,
      fail: false,
      done: false,
      level: QUERY_LEVEL.BASIC_SEARCH,
      error: null,
    },
    sap: {
      drain: false,
      fail: false,
      done: false,
      level: QUERY_LEVEL.BASIC_SEARCH,
      error: null,
    }
  }
};

export const searchProductsReducer = createReducer(initialState,
  on(resetState, (_) => (initialState)),
  on(searchProducts, (state, { searchValues }) => ({ ...state, searchValues})),
  on(setAdditionalSearchFormData,
    (state, {streets, street, showStreetAlert, cities, city, queryLevels}) =>
    ({ ...state,
      streets,
      cities,
      searchValues: { ...state.searchValues, street, city},
      showStreetAlert,
      queryLevels})),
  on(setProducts, (state, {products, basicSupplyProduct, mbsProducts, queryLevels}) => {
      return { ...state, products, basicSupplyProduct, mbsProducts, showStreetAlert: false, queryLevels};
    }),
  on(hideStreetAlert, (state) => ({ ...state, showStreetAlert: false})),
  on(updateSearchValues, (state, {searchValues}) => ({ ...state, searchValues})),
  on(updateHeatPumpValues, (state, {heatPumpValues}) => ({ ...state, heatPumpValues})),
  on(updateDynamicValue, (state, {dynamicTariffValue}) => ({ ...state, dynamicTariffValue})),
  on(updateSearchDate, (state, {moveInDate}) => ({ ...state, searchValues: { ...state.searchValues, moveInDate}})),
  on(updateProducts, (state, {products}) => ({ ...state, products, selectedProduct: initialState.selectedProduct})),
  on(updateProductsWithPatch, (state, {products, selected}) => ({ ...state, products, selectedProduct: selected})),
  on(resetStreet, (state) => ({ ...state, streets: [], searchValues: { ...state.searchValues, street: ''}})),
  on(resetCity, (state) => ({ ...state, cities: [], searchValues: { ...state.searchValues, city: ''}})),
  on(resetProducts, (state) => ({
    ...state,
    products: initialState.products,
    mbsProducts: initialState.mbsProducts,
    selectedProduct: initialState.selectedProduct,
    selectedMbsProduct: initialState.selectedMbsProduct
  })),
  on(resetQueryLevels, (state) => ({
    ...state,
    queryLevels: initialState.queryLevels
  })),
  on(selectProduct, (state, {selectedProduct}) => ({ ...state, selectedProduct, selectedMbsProduct: null })),
  on(selectMbsProduct, (state, {selectedMbsProduct}) => ({ ...state, selectedMbsProduct, selectedProduct: null })),
  on(resetSelectProduct, (state) => ({ ...state,
    selectedProduct: initialState.selectedProduct,
    selectedMbsProduct: initialState.selectedMbsProduct })),
  on(setProductsError, (state, ) => {
    return { ...state, productMappingError: true};
  }),
  on(resetProductsError, (state, ) => ({ ...state, productMappingError: false})),
  on(updateMbsProduct, (state, {mbsProduct, runtime, section}) => {
    const idx = state.mbsProducts.findIndex(p => {
      return p.name.some(n => n === p.name[0]);
    });
    if (idx !== -1) {
      const newProd = new MbsProduct(
        section,
        {
          expenses: mbsProduct.expenses,
          isSufficientInformationProvided: mbsProduct.isSufficientInformationProvided,
          priceInformation: mbsProduct.apiIfc
        },
        mbsProduct.activeRuntimeMonths,
        state.heatPumpValues?.tariffId);
      newProd.setActiveRuntime(runtime);
      const tempProds = [...state.mbsProducts];
      tempProds[idx] = newProd;
      return {
        ...state,
        mbsProducts: tempProds
      };
    } else {
      return {...state};
    }
  })
);
