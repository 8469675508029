import { LswOption } from '@ui/lsw-lib';
import { CustomerType, SectorTypeDetails } from '../../_models/interface';
import { SectorType } from '@ekso/ekso-types';

export const privateGasConsumptionOptions: LswOption<string, string>[] = [
  {
    value: '5000',
    viewValue: `5.000 kWh <span style="color: lightgray; padding-left: 10px;">30 qm</span>`
  },
  {
    value: '8000',
    viewValue: `8.000 kWh <span style="color: lightgray; padding-left: 10px;">60 qm</span>`
  },
  {
    value: '11000',
    viewValue: `11.000 kWh <span style="color: lightgray; padding-left: 10px;">90 qm</span>`
  },
  {
    value: '14000',
    viewValue: `14.000 kWh <span style="color: lightgray; padding-left: 10px;">120 qm</span>`
  },
  {
    value: '18000',
    viewValue: `18.000 kWh <span style="color: lightgray; padding-left: 10px;">RH</span>`
  },
  {
    value: '20000',
    viewValue: `20.000 kWh <span style="color: lightgray; padding-left: 10px;">EFH</span>`
  }
];

export const businessGasConsumptionOptions: LswOption<string, string>[] = [
  {
    value: '10000',
    viewValue: `10.000 kWh`
  },
  {
    value: '20000',
    viewValue: `20.000 kWh`
  },
  {
    value: '30000',
    viewValue: `30.000 kWh`,
  },
  {
    value: '50000',
    viewValue: `50.000 kWh`,
  },
  {
    value: '70000',
    viewValue: `70.000 kWh`,
  },
  {
    value: '90000',
    viewValue: `90.000 kWh`,
  }
];

export const businessPowerConsumptionOptions: LswOption<string, string>[] = [
  {
    viewValue: `7.500 kWh`,
    value: '7500'
  },
  {
    viewValue: `15.000 kWh`,
    value: '15000'
  },
  {
    viewValue: `25.000 kWh`,
    value: '25000'
  },
  {
    viewValue: `40.000 kWh`,
    value: '40000'
  },
  {
    viewValue: `60.000 kWh`,
    value: '60000'
  },
  {
    viewValue: `80.000 kWh`,
    value: '80000'
  }
];

export const privateHeatPumpConsumptionOptions: LswOption<string, string>[] = [
  {
    viewValue: `3.000 kWh`,
    value: '3000'
  },
  {
    viewValue: `5.000 kWh`,
    value: '5000'
  },
  {
    viewValue: `7.000 kWh`,
    value: '7000'
  },
  {
    viewValue: `9.000 kWh`,
    value: '9000'
  }
];

export const businessHeatPumpConsumptionOptions: LswOption<string, string>[] = [
  {
    viewValue: `5.000 kWh`,
    value: '5000'
  },
  {
    viewValue: `7.000 kWh`,
    value: '7000'
  },
  {
    viewValue: `9.000 kWh`,
    value: '9000'
  },
  {
    viewValue: `12.000 kWh`,
    value: '12000'
  }
];

export const sectorTypeOptions: SectorTypeDetails[] = [
  { value: SectorType.POWER, viewValue: 'SEARCHFORM.POWER', icon: '/common/images/strom.svg', enabled: true },
  { value: SectorType.GAS, viewValue: 'SEARCHFORM.GAS', icon: '/common/images/gas.svg', enabled: true },
  { value: SectorType.HEATPUMP, viewValue: 'SEARCHFORM.HEATPUMP', icon: '/common/images/heatpump.svg', enabled: true },
  { value: SectorType.DYNAMIC, viewValue: 'SEARCHFORM.DYNAMIC', icon: '/common/images/strom.svg', enabled: true },
];
export const customerTypeOptions: LswOption<string, string>[] = [
  { value: 'PRIVATE', viewValue: 'SEARCHFORM.PRIVATE' },
  { value: 'BUSINESS', viewValue: 'SEARCHFORM.BUSINESS' }
];

export const dataDateFormat = 'yyyy-MM-dd';
export const dataDateLocal = 'de';

export const allowedZipCodes = [
  '04416',
  '04420',
  '04425',
  '04442',
  '04451',
  '04463',
  '04509',
  '04519',
  '04523',
  '04539',
  '04552',
  '04564',
  '04565',
  '04567',
  '04571',
  '04575',
  '04613',
  '04651',
  '04668',
  '04683',
  '04687',
  '04808',
  '04821',
  '04824',
  '04827',
  '04828',
  '04838',
  '06184',
  '06188',
  '06217',
  '06231',
  '06237',
  '06258',
  '06679',
  '06686',
  '06688',
  '06796',
  '06809',
  '04105',
  '04207',
  '04209',
  '04229',
  '04249',
  '04275',
  '04277',
  '04299',
  '04317',
  '04349',
  '04357',
  '04103',
  '04107',
  '04109',
  '04129',
  '04155',
  '04157',
  '04159',
  '04177',
  '04179',
  '04205',
  '04279',
  '04288',
  '04289',
  '04315',
  '04318',
  '04319',
  '04328',
  '04329',
  '04347',
  '04356',
  '04435',
  '04158',
  '04178',
];

export const FilteredOutPlusProductsID: {
  [k_sector in SectorType]: {
    [k_customer in CustomerType]: string[]
  }
} = {
  POWER: {
    BUSINESS: [],
    PRIVATE: ['STROM_LPLUS_PRIVAT']
  },
  GAS: {
    BUSINESS: [],
    PRIVATE: ['GAS_LPLUS_PRIVAT']
  },
  HEATPUMP: {
    BUSINESS: [],
    PRIVATE: []
  },
  DYNAMIC: {
    BUSINESS: [],
    PRIVATE: []
  }
};
