<div *ngIf="loaderService.isLoading | async">
  <lsw-loadingbar></lsw-loadingbar>
</div>
<ng-container *ngIf="maintenanceMode$ | async">
  <lib-maintenance-block></lib-maintenance-block>
</ng-container>
<lib-search-form
  *ngIf="(maintenanceMode$ | async) === false"
  [conf]="searchFormConf"
  [hideIfPrefilled]="false"
  [redirectUrl]="productsUrl"
  [apiUrl]="apiUrl"
>
</lib-search-form>
