import { Injectable } from '@angular/core';
import { AppMode, Environment } from '@ekso/ekso-types';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class AppSettingsService {
  public static get appMode(): AppMode {
    return AppSettingsService._appMode;
  }
  public static set appMode(mode: AppMode) {
    AppSettingsService._appMode = mode;
  }

  public static get environment(): Environment {
    return AppSettingsService._environment;
  }
  public static set environment(environment: Environment) {
    AppSettingsService._environment = environment;
  }

  public static setMaintenanceMode(isMaintenanceModeOn: boolean) {
    AppSettingsService._isMaintenanceModeOn.next(isMaintenanceModeOn);
  }

  public static isMaintenanceModeOn(): Observable<boolean> {
    return AppSettingsService._isMaintenanceModeOn.asObservable();
  }

  private static _appMode: AppMode = AppMode.NORMAL;
  private static _environment: Environment = Environment.DEV;
  private static _isMaintenanceModeOn = new BehaviorSubject<boolean>(false);
}
