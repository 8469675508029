import { SectorType } from './interface';
import { SECTION_ENUM } from './mbs/transition.msb.types';

export enum customerTypeMapping {
  PRIVATE = '0001',
  BUSINESS = '0002'
}

export enum CustomerTypeEnum {
  PRIVATE = 'PRIVATE',
  BUSINESS = 'BUSINESS'
}

export enum SectorTypeEnum {
  POWER = 'POWER',
  GAS = 'GAS',
  HEATPUMP = 'HEATPUMP',
  DYNAMIC = 'DYNAMIC'
}

export enum sectorTypeMapping {
  POWER = '01',
  GAS = '02',
  HEATPUMP = '03',
  DYNAMIC = '04'
}

export enum CustomerStatusEnum {
  NEW = 1,
  OLD = 2
}

export const MbsSectionEnumTypeMapper: { mbsKey: SECTION_ENUM, sapKey: sectorTypeMapping, sector: SectorType }[] = [
  { mbsKey: SECTION_ENUM.GAS, sapKey: sectorTypeMapping.GAS, sector: 'GAS' },
  { mbsKey: SECTION_ENUM.STROM, sapKey: sectorTypeMapping.POWER, sector: 'POWER' },
  { mbsKey: SECTION_ENUM.STROM, sapKey: sectorTypeMapping.HEATPUMP, sector: 'HEATPUMP' },
  { mbsKey: SECTION_ENUM.STROM, sapKey: sectorTypeMapping.DYNAMIC, sector: 'DYNAMIC' }
];

export function mapMbsSectionToSectorMapping(section: SECTION_ENUM): sectorTypeMapping {
  return MbsSectionEnumTypeMapper.find(e => e.mbsKey === section).sapKey;
}

export function mapMbsSectionToSector(section: SECTION_ENUM): SectorType {
  return MbsSectionEnumTypeMapper.find(e => e.mbsKey === section).sector;
}

export function mapSectortoMbsEnum(sector: sectorTypeMapping): SECTION_ENUM {
  return MbsSectionEnumTypeMapper.find(e => e.sapKey === sector).mbsKey;
}

export function mapSectorTypetoMbsEnum(sector: SectorType): SECTION_ENUM {
  return MbsSectionEnumTypeMapper.find(e => e.sector === sector).mbsKey;
}

export const enum FeatureType {
  AUTHORIZED = 0,
  UNAUTHORIZED = 1,
}

export enum SimplifiedHttpResponse {
  SUCCESS = 0,
  FAILURE = 1,
  TIME_OUT = 2,
  CREDIT_RATING_FAILED = 3,
}
