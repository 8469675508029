import {
  AfterContentChecked,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { MediaObserver } from '@angular/flex-layout';
import {
  AbstractControl,
  FormControl,
  UntypedFormBuilder,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { LswOption } from '@ui/lsw-lib';
import { LswTabsLabel } from '@ui/lsw-lib/src/lib/components/tabs';
import * as moment from 'moment';
import {
  BehaviorSubject,
  Observable,
  Subscription,
  combineLatest,
  merge,
  of,
} from 'rxjs';
import { catchError, concatMap, map, startWith, take } from 'rxjs/operators';
import { HEATPUMP_PRODUCTS } from '../../_models/app.consts';
import { CustomerTypeEnum, SectorTypeEnum, SimplifiedHttpResponse } from '../../_models/enum';
import {
  FeatureTypeIfc,
  HeatPumpValues,
  QUERY_LEVEL,
  QueryLevels,
  RedirectToProductPageResult,
  SearchFormAppConfig,
  SearchValues,
  SectorType,
  SectorTypeDetails,
} from '../../_models/interface';
import { EtrackerService } from '../../etracker/_services/etracker.service';
import { CommonService } from '../../services/common.service';
import { ErrorService } from '../../services/error.service';
import { LoaderService } from '../../services/loader.service';
import { MbsProductService } from '../../services/mbs-product-service.service';
import { SharedConfiguration } from '../../shared.configuration';
import {
  hideStreetAlert,
  resetCity,
  resetProductsError,
  resetQueryLevels,
  resetState,
  resetStreet,
  searchProducts,
  setAdditionalSearchFormData,
  updateDynamicValue,
  updateHeatPumpValues,
  updateSearchValues,
} from '../../state/app.actions';
import { AppEffects } from '../../state/app.effects';
import {
  getCities,
  getProductsError,
  getSearchValues,
  getState,
  getStreetAlert,
  getStreets,
  productsFilled,
  queryLevels,
  showExtraControls,
} from '../../state/app.selectors';
import { AppService } from '../../state/app.service';
import { AppState } from '../../state/app.state';
import { SearchFormService } from './search-form.service';
import {
  customerTypeOptions,
  sectorTypeOptions,
} from './searchfrom.constants';

type ControlName =
  | 'moveInDate'
  | 'customerType'
  | 'sectorType'
  | 'consumption'
  | 'zipCode'
  | 'street'
  | 'city'
  | 'houseNo'
  | 'customerTypeSwitch';

type PathSectorName = 'gas' | 'strom' | 'waermestrom' | 'dynamicPower';
type QuerySectorName = 'gas' | 'strom' | 'waermestrom' | 'dynamicPower';
interface TabMappingInterface {
  enum: SectorType;
  queryParam: { name: string; value: QuerySectorName };
  pathEnd: PathSectorName;
}

enum TAB_INDEX {
  STROM = 0,
  GAS = 1,
  HEATPUMP = 2,
  DYNAMIC = 3,
}

@Component({
  selector: 'lib-search-form',
  templateUrl: './search-form.component.html',
  styleUrls: ['./search-form.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SearchFormComponent implements OnInit, AfterContentChecked {
  customerTypeOptions = customerTypeOptions;
  sectorTypeOptions = sectorTypeOptions;
  hasError: Observable<boolean>;
  hidePrefilled = true;
  showDate: boolean;
  regioDelivery: boolean;
  showUnsupportedZipCodeWarning = false;
  customerType: string;
  showConsumptionDropdown: boolean;
  showConsumptionDropdown$ = new BehaviorSubject<boolean>(true);
  public showCustomerSelect: boolean;
  private showSectorSelect: boolean;
  hideHeatPump: boolean;
  private showDynamicPowerTab: boolean;
  heatPumpPrivilegeLink: string;
  heatPumpConsumptionLink: string;
  showNoProductMessage = false;
  streetOptions: LswOption[] = [];

  getCityResponseMessage = {
    [SimplifiedHttpResponse.SUCCESS]: 'Die eingegebene Postleitzahl existiert nicht.',
    [SimplifiedHttpResponse.TIME_OUT]: 'IT-Probleme: Bitte versuchen sie es in Kürze erneut.',
    [SimplifiedHttpResponse.FAILURE]: 'IT-Probleme: Bitte versuchen sie es in Kürze erneut.',
  }

  public tabIndexEnum = TAB_INDEX;

  @Input() conf: SearchFormAppConfig;
  @Input() hideIfPrefilled = false;
  @Input() redirectUrl: string;
  @Input() apiUrl: string;
  @Input() shouldRedirect = true;
  @Input() initWithStorage = false;
  @Input() useQueryParams = true;

  public tabsLabels: LswTabsLabel[] = [];

  searchForm = this.fb.group({
    moveInDate: [new Date(), [Validators.required]],
    customerType: ['', Validators.required],
    customerTypeSwitch: [false, false],
    sectorType: ['', Validators.required],
    consumption: ['', [Validators.required, Validators.pattern('^[0-9]*$')]],
    zipCode: [
      '',
      [
        Validators.required,
        Validators.minLength(5),
        Validators.pattern('^(?!01000|99999)(0[1-9]\\d{3}|[1-9]\\d{4})$'),
      ],
      [
        (ctrl: AbstractControl) => this.getCitiesErrorMessage(ctrl.value),
      ],
    ],
    street: [''],
    city: [''],
    houseNo: ['', [Validators.pattern('^[0-9]*$')]],
  });

  public heatPumpForm = this.fb.group({
    mainQuestion: [null, Validators.required],
    firstQuestion: [null, Validators.required],
    secondQuestion: [null, Validators.required],
    tariffId: [null],
  });

  public heatPumpOptions: LswOption<boolean, string>[] = [
    { value: true, viewValue: 'Ja' },
    { value: false, viewValue: 'Nein' },
  ];

  public dynamicPowerOptions: LswOption<boolean, string>[] = [
    { value: true, viewValue: 'Ja' },
    { value: false, viewValue: 'Nein' },
  ];

  public dynamicPowerControl = new FormControl<boolean>(null);

  public get firstQuestionControl() {
    return this.heatPumpForm.get('firstQuestion') as UntypedFormControl;
  }
  public get secondQuestionControl() {
    return this.heatPumpForm.get('secondQuestion') as UntypedFormControl;
  }
  public get mainQuestionControl() {
    return this.heatPumpForm.get('mainQuestion') as UntypedFormControl;
  }
  public get tariffIdControl() {
    return this.heatPumpForm.get('tariffId') as UntypedFormControl;
  }
  state$: Observable<AppState>;
  searchValues$: Observable<SearchValues>;
  streetOptions$: Observable<LswOption[]>;
  cityOptions$: Observable<LswOption[]>;
  showStreetAlert$: Observable<boolean>;
  extraControls$: Observable<boolean>;
  productsFilled$: Observable<boolean>;
  productsError$: Observable<boolean>;
  queryLevels$: Observable<QueryLevels>;
  tabSelection$: Observable<number>;
  tabSelection = new BehaviorSubject<TAB_INDEX>(TAB_INDEX.STROM);
  consumptionOptions: any = [];
  testRender = true;

  shouldSubmitBeVisible$: Observable<boolean>;

  readonly tabMapper: {
    gas: TabMappingInterface;
    electricity: TabMappingInterface;
    heatpump: TabMappingInterface;
    dynamicPower: TabMappingInterface;
  } = {
      gas: {
        enum: SectorTypeEnum.GAS,
        pathEnd: 'gas',
        queryParam: { name: 'section', value: 'gas' },
      },
      electricity: {
        enum: SectorTypeEnum.POWER,
        pathEnd: 'strom',
        queryParam: { name: 'section', value: 'strom' },
      },
      heatpump: {
        enum: SectorTypeEnum.HEATPUMP,
        pathEnd: 'waermestrom',
        queryParam: { name: 'section', value: 'waermestrom' },
      },
      dynamicPower: {
        enum: SectorTypeEnum.DYNAMIC,
        pathEnd: 'dynamicPower',
        queryParam: { name: 'section', value: 'dynamicPower' },
      },
    };

  constructor(
    private fb: UntypedFormBuilder,
    public searchFormService: SearchFormService,
    private errorService: ErrorService,
    public commonService: CommonService,
    private store: Store,
    private appEffects: AppEffects,
    public loaderService: LoaderService,
    public mediaObserver: MediaObserver,
    public sharedConfig: SharedConfiguration,
    private appService: AppService,
    private route: ActivatedRoute,
    private changeDetector: ChangeDetectorRef,
    private translate: TranslateService,
    private mbsProductService: MbsProductService
  ) {}

  ngOnInit() {

    this.state$ = this.store.select(getState);
    this.productsError$ = this.store.select(getProductsError);
    this.searchValues$ = this.store.select(getSearchValues);
    this.cityOptions$ = this.store.select(getCities);
    this.showStreetAlert$ = this.store.select(getStreetAlert);
    this.queryLevels$ = this.store.select(queryLevels);
    this.extraControls$ = this.store.select(showExtraControls);
    this.productsFilled$ = this.store.select(productsFilled);
    this.streetOptions$ = this.store.select(getStreets);
    this.streetOptions$.subscribe((streets) => (this.streetOptions = streets));

    combineLatest([
      this.mbsProductService.loadRegioDelivery(this.apiUrl, moment()),
      this.tabSelection,
    ]).subscribe(([ff, tabIndex]: [FeatureTypeIfc, TAB_INDEX]) => {
      this.searchForm.markAsUntouched();
      this.regioDelivery = ff.enabled;
      this.updateDeliveryDateVisibility(tabIndex);
    });

    this.patchInitialFormValuesBasedOnProperties();
    this.initWithStorage
      ? this.subscribeFormToInitialState()
      : this.initBlankForm();
    this.customerType = this.searchForm.get('customerType').value;
    this.showConsumptionDropdown = this.conf.showConsumptionDropdown;
    this.showCustomerSelect = this.conf.showCustomerSelect;
    this.showSectorSelect = this.conf.showSectorSelect;
    this.hideHeatPump = this.conf.hideHeatPump;
    this.showDynamicPowerTab = this.conf.showDynamicPowerTab;
    this.heatPumpPrivilegeLink = this.conf.heatPumpPrivilegeLink;
    this.heatPumpConsumptionLink = this.conf.heatPumpConsumptionLink;
    this.shouldSectorSelectBeVisible();
    this.onShowConsumptionDropdownChange();
    this.subscribeInitialStreetAndCitySelect();
    this.subscribePageRedirect();
    this.resetStreetOnZipCodeOrSectorChange();
    this.subscribeToQueryStateResettingControls();
    this.setAdditionalControlsValidation();
    this.subscribeFormValueChange();
    this.subscribeHeatPumpFormValueChange();
    this.validateConsumption();
    this.initValidateConsumption();
    this.initSearchTab();
    this.generatePrefilledValueString();
    this.updateDynamicValue();
    this.shouldSubmitBeVisible$ = this.shouldSubmitBeVisible();

    this.hasError = this.errorService.hasError.pipe(
      concatMap((httpError) => {
        return this.productsFilled$.pipe(
          map((filled) => {
            return { filled, httpError };
          })
        );
      }),
      map((composed) => {
        if (composed.filled) {
          return false;
        } else {
          return composed.httpError;
        }
      })
    );

    this.consumptionOptions = this.setConsumptionOptions();

    merge(
      this.searchForm.controls.customerType.valueChanges,
      this.searchForm.controls.sectorType.valueChanges
    ).subscribe(() => {
      this.onSuggestionControlChange();
    });
  }

  private initValidateConsumption() {
    merge([
      this.searchForm.get('consumption').valueChanges,
      this.searchForm.get('zipCode').valueChanges,
    ]).subscribe(() => {
      this.validateConsumption();
    });
  }

  preventNonNumericKeysForDate($event: KeyboardEvent) {
    const nonNumericKey = /^[a-zA-Z\-\/\\]$/.test($event.key);

    if (nonNumericKey) {
      $event.preventDefault();
    }
  }

  ngAfterContentChecked(): void {
    this.changeDetector.detectChanges();
  }

  private initSearchTab() {
    const lastSegment: PathSectorName = window.location.pathname
      .split('/')
      .filter((v) => !!v)
      .pop() as PathSectorName;
    /* Check for /gas or /strom in URL path ending */
    if (
      Object.keys(this.tabMapper)
        .map<PathSectorName>((k) => this.tabMapper[k].pathEnd)
        .includes(lastSegment)
    ) {
      this.tabSelection$ = new Observable((subscriber) => {
        if (lastSegment === this.tabMapper.gas.pathEnd) {
          subscriber.next(TAB_INDEX.GAS);
          this.tabSelection.next(TAB_INDEX.GAS);
        } else if (lastSegment === this.tabMapper.electricity.pathEnd) {
          subscriber.next(TAB_INDEX.STROM);
          this.tabSelection.next(TAB_INDEX.STROM);
        } else if (lastSegment === this.tabMapper.heatpump.pathEnd) {
          subscriber.next(TAB_INDEX.HEATPUMP);
          this.tabSelection.next(TAB_INDEX.HEATPUMP);
        } else {
          subscriber.next(TAB_INDEX.DYNAMIC);
          this.tabSelection.next(TAB_INDEX.DYNAMIC);
        }
        subscriber.complete();
      });
    } else {
      this.route.queryParams.subscribe((params) => {
        if (
          Object.keys(params).length > 0 &&
          (params[this.tabMapper.electricity.queryParam.name] ||
            params[this.tabMapper.gas.queryParam.name])
        ) {
          this.tabSelection$ = new Observable((subscriber) => {
            if (params.section === this.tabMapper.gas.queryParam.value) {
              subscriber.next(TAB_INDEX.GAS);
              this.tabSelection.next(TAB_INDEX.GAS);
            } else if (
              params.section === this.tabMapper.electricity.queryParam.value
            ) {
              subscriber.next(TAB_INDEX.STROM);
              this.tabSelection.next(TAB_INDEX.STROM);
            } else if (lastSegment === this.tabMapper.heatpump.queryParam.value) {
              subscriber.next(TAB_INDEX.HEATPUMP);
              this.tabSelection.next(TAB_INDEX.HEATPUMP);
            } else {
              subscriber.next(TAB_INDEX.DYNAMIC);
              this.tabSelection.next(TAB_INDEX.DYNAMIC);
            }
            subscriber.complete();
          });
        } else {
          this.tabSelection$ = this.searchValues$.pipe(
            take(1),
            map((sv) => {
              this.tabSelection.next(
                this.mapSectorType(sv ? sv.sectorType : this.conf.sectorType)
              );
              return this.mapSectorType(
                sv ? sv.sectorType : this.conf.sectorType
              );
            })
          );
        }
      });
    }
  }

  private updateDeliveryDateVisibility(tabIndex: TAB_INDEX) {
    const isGasOrElectricity = [TAB_INDEX.STROM, TAB_INDEX.GAS].includes(
      tabIndex
    );
    this.showDate = this.regioDelivery && isGasOrElectricity;
  }

  private onSuggestionControlChange() {
    this.testRender = false;
    const customerType = this.searchForm.get('customerType').value;
    const sectorType = this.searchForm.get('sectorType').value;
    this.consumptionOptions =
      this.searchFormService.getConsumptionOptions()[customerType][sectorType];
    setTimeout(() => {
      this.testRender = true;
      this.searchForm.controls.consumption.reset();
    }, 1);
  }

  public onSubmit() {
    this.showNoProductMessage = false;

    if (
      this.searchFormService.shouldTakeOfferViaPlatform(
        this.control('customerType')?.value,
        this.control('consumption')?.value,
        this.control('sectorType')?.value
      )
    ) {
      if (this.conf.bigConsumptionUrl.includes('preisrechner')) {
        window.open(
          `https://preisrechner.l.de/#${this.control('sectorType')?.value === SectorTypeEnum.GAS ? 'gas' : 'power'
          }`,
          '_self'
        );
      } else {
        window.open(this.conf.bigConsumptionUrl, '_self');
      }
      return;
    }

    this.updateTariffId(this.heatPumpForm.value);

    this.hidePrefilled = true;
    if (this.showPrefilledString) {
      this.generatePrefilledValueString();
    }

    this.queryLevels$.pipe(take(1)).subscribe((ql) => {
      this.store.dispatch(
        searchProducts({
          apiUrl: this.apiUrl,
          queryLevels: ql,
          searchValues: this.searchForm.value,
          shouldRedirect: this.shouldRedirect,
          heatPumpValues: this.heatPumpForm.value,
          config: {
            featuresUrl: this.apiUrl,
          } /*  */,
        })
      );
    });
  }

  streetFilteringFn(): (fieldInput: string) => Observable<LswOption[]> {
    return (val: string) => {
      if (this.streetOptions) {
        return of(
          this.streetOptions.filter(
            (option) =>
              option.value.toLowerCase().includes(val.toLowerCase()) ||
              option.viewValue.toLowerCase().includes(val.toLowerCase())
          )
        );
      } else {
        return of([]);
      }
    };
  }

  onStreetSelectBlur() {
    setTimeout(() => {
      if (
        this.streetOptions.findIndex(
          (x) => x.value === this.control('street').value
        ) === -1
      ) {
        this.control('street').setValue('');
      }
    }, 200);
  }

  private setAdditionalControlsValidation() {
    let sub: Subscription;
    this.extraControls$.subscribe((v) => {
      if (v) {
        this.onStreetSelectBlur();
        this.searchForm.get('street').setValidators(Validators.required);
        this.searchForm.get('city').setValidators(Validators.required);
        this.searchForm
          .get('houseNo')
          .setValidators([
            Validators.required,
            Validators.pattern('^[0-9-]*$'),
          ]);
        sub = this.subscribeToCityChange();
        this.searchForm.updateValueAndValidity();
      } else {
        if (sub) {
          sub.unsubscribe();
        }
        this.searchForm.get('street').clearValidators();
        this.searchForm.get('city').clearValidators();
        this.searchForm.get('houseNo').clearValidators();
        this.searchForm.get('houseNo').updateValueAndValidity();
      }
    });
  }

  private subscribeToQueryStateResettingControls() {
    merge(
      this.searchForm.get('customerType').valueChanges,
      this.searchForm.get('sectorType').valueChanges,
      this.searchForm.get('consumption').valueChanges,
      this.searchForm.get('zipCode').valueChanges
    ).subscribe(() => {
      this.store.dispatch(resetQueryLevels());
    });
  }

  private subscribeToCityChange(): Subscription {
    return this.searchForm.get('city').valueChanges.subscribe((newCity) => {
      this.searchForm.get('street').setValue('');
      this.state$
        .pipe(
          take(1),
          concatMap((s) => {
            if (s.queryLevels.mbs.level === QUERY_LEVEL.DETAILED_SEARCH) {
              return this.appService
                .getStreetsMbs(this.apiUrl, s.searchValues.zipCode, newCity)
                .pipe(map((streets) => ({ newStreets: streets, state: s })));
            } else if (
              s.queryLevels.sap.level === QUERY_LEVEL.DETAILED_SEARCH
            ) {
              return this.appService
                .getStreets(this.apiUrl, s.searchValues.zipCode, newCity)
                .pipe(
                  map((regioSet) => {
                    return {
                      state: s,
                      newStreets: regioSet.map((rs) => rs.Street),
                    };
                  })
                );
            }
          })
        )
        .subscribe((v) => {
          this.store.dispatch(
            setAdditionalSearchFormData({
              streets: v.newStreets.map<LswOption>((s) => ({
                value: s,
                viewValue: s,
              })),
              street: v.newStreets[0],
              city: v.state.searchValues.city,
              cities: v.state.cities,
              showStreetAlert: false,
              queryLevels: {
                mbs: {
                  level: QUERY_LEVEL.DETAILED_SEARCH,
                  fail: false,
                  drain: false,
                  done: false,
                  error: null,
                },
                sap: {
                  level: QUERY_LEVEL.DETAILED_SEARCH,
                  fail: false,
                  drain: false,
                  done: false,
                  error: null,
                },
              },
            })
          );
        });
    });
  }

  private subscribeFormToInitialState() {
    this.state$.pipe(take(1)).subscribe((state) => {
      if (state.searchValues) {
        this.searchForm.patchValue(state.searchValues);
      }
      if (state.dynamicTariffValue) {
        this.dynamicPowerControl.patchValue(state.dynamicTariffValue);
      }
      if (!state.products && this.hideIfPrefilled) {
        this.trackSearchFormView();
      }
      this.shouldSubmitBeVisible();
    });
  }

  private initBlankForm() {
    this.store.dispatch(resetState());
    this.trackSearchFormView();
  }

  private subscribePageRedirect() {
    if (this.shouldRedirect) {
      this.appEffects.redirectToProductPage.subscribe(
        (products: RedirectToProductPageResult) => {
          this.showNoProductMessage =
            products.mbsProducts === null ||
            (products.mbsProducts && products.mbsProducts.length === 0);
          if (!this.showNoProductMessage && this.redirectUrl) {
            window.location.href = this.redirectUrl;
          }
        }
      );
    }
  }

  private subscribeInitialStreetAndCitySelect() {
    this.appEffects.setInitialCity.subscribe((city: string) => {
      this.searchForm.patchValue({ city });
    });
  }

  private subscribeHeatPumpFormValueChange() {
    this.firstQuestionControl.valueChanges.subscribe((value) => {
      if (!value) {
        this.secondQuestionControl.reset();
      }
    });
  }

  private subscribeFormValueChange() {
    this.searchForm.valueChanges.subscribe((value) => {
      this.store.dispatch(hideStreetAlert());
      this.store.dispatch(resetProductsError());
      this.store.dispatch(updateSearchValues({ searchValues: value }));
    });
    this.searchForm.get('customerTypeSwitch').valueChanges.subscribe(() => {
      this.searchForm
        .get('customerType')
        .setValue(
          this.searchForm.get('customerTypeSwitch').value
            ? CustomerTypeEnum.BUSINESS
            : CustomerTypeEnum.PRIVATE
        );
      this.customerType = this.searchForm.get('customerType').value;
      this.onShowConsumptionDropdownChange();
    });
  }

  public shouldSubmitBeVisible(): Observable<boolean> {
    return combineLatest([
      this.tabSelection,
      this.mainQuestionControl.valueChanges.pipe(startWith('')),
      this.firstQuestionControl.valueChanges.pipe(startWith('')),
    ]).pipe(
      map(
        ([tabSelection, mainQuestionValue, firstQuestionValue]) =>
          tabSelection !== TAB_INDEX.HEATPUMP && tabSelection !== TAB_INDEX.DYNAMIC ||
          (tabSelection === TAB_INDEX.HEATPUMP &&
            mainQuestionValue === true &&
            firstQuestionValue === true)
      )
    );
  }

  public control(controlName: ControlName): UntypedFormControl {
    return this.searchForm.get(controlName) as UntypedFormControl;
  }

  public setConsumptionOptions(): LswOption<string, string>[] {
    const customerType = this.searchForm.get('customerType').value;
    const sectorType = this.searchForm.get('sectorType').value;
    return this.searchFormService.getConsumptionOptions()[customerType][
      sectorType
    ];
  }

  private resetStreetOnZipCodeOrSectorChange(): void {
    merge(
      this.searchForm.controls.zipCode.valueChanges,
      this.searchForm.controls.sectorType.valueChanges
    ).subscribe(() => {
      this.store.dispatch(resetStreet());
      this.store.dispatch(resetCity());
    });
  }

  private patchInitialFormValuesBasedOnProperties(): void {
    this.searchForm.patchValue({
      customerType: this.conf.customerType,
      sectorType: this.conf.sectorType,
      customerTypeSwitch: this.conf.customerType === CustomerTypeEnum.BUSINESS ? true : false,
    });
  }

  public validateConsumption(): void {
    const consumptionCtrl = this.control('consumption');

    if (consumptionCtrl.hasError('consumptionNotAllowed')) {
      delete consumptionCtrl.errors.consumptionNotAllowed;
      consumptionCtrl.updateValueAndValidity();
    }
  }

  get showPrefilledString(): boolean {
    return this.hideIfPrefilled && this.hidePrefilled;
  }

  public trackSearchFormView() {
    EtrackerService.trackForm('formView', '01_SearchForm');
  }

  public onTabClick(e: TAB_INDEX) {
    this.searchForm.controls.sectorType.setValue(sectorTypeOptions[e].value);
    this.tabSelection.next(e);
  }

  private onShowConsumptionDropdownChange(): void {
    if (this.showConsumptionDropdown) {
      this.showConsumptionDropdown$.next(true);
    } else if (this.isPrivateCustomer()) {
      this.showConsumptionDropdown$.next(true);
    } else {
      this.showConsumptionDropdown$.next(false);
    }
  }

  private isPrivateCustomer(): boolean {
    return this.customerType === CustomerTypeEnum.PRIVATE;
  }

  private shouldSectorSelectBeVisible() {
    if (this.showSectorSelect) {
      this.updateTabsLabelsFromTranslations();
    }
  }

  public shouldSubmitBeDisabled(): boolean {
    return (
      this.showUnsupportedZipCodeWarning ||
      !this.searchForm.valid ||
      (this.tabSelection.getValue() === TAB_INDEX.HEATPUMP &&
        this.secondQuestionControl.value === null)
    );
  }

  private updateTariffId(value: HeatPumpValues) {
    const sectorType = this.searchForm.get('sectorType').value;
    if (sectorType === SectorTypeEnum.HEATPUMP) {
      this.tariffIdControl.patchValue(
        value.secondQuestion === true || value.secondQuestion === false
          ? HEATPUMP_PRODUCTS[this.customerType][value.secondQuestion].tariffId
          : null,
        { emitEvent: false }
      );
    } else {
      this.tariffIdControl.reset();
    }
    this.store.dispatch(
      updateHeatPumpValues({ heatPumpValues: this.heatPumpForm.value })
    );
  }

  private updateDynamicValue() {
    this.dynamicPowerControl.valueChanges.subscribe((value: boolean) => {
      this.store.dispatch(updateDynamicValue({ dynamicTariffValue: value }))
    })
  }

  public mapSectorType(sectorType: SectorType): TAB_INDEX {
    switch (sectorType) {
      case SectorTypeEnum.GAS:
        return TAB_INDEX.GAS;
      case SectorTypeEnum.POWER:
        return TAB_INDEX.STROM;
      case SectorTypeEnum.HEATPUMP:
        return TAB_INDEX.HEATPUMP;
      case SectorTypeEnum.DYNAMIC:
        return TAB_INDEX.DYNAMIC;
      default:
        break;
    }
  }

  private generatePrefilledValueString() {
    this.searchFormService.generatePrefilledValueString(
      { ...this.searchForm.value },
      false
    );
  }

  private updateTabsLabelsFromTranslations() {
    this.translate.getTranslation('de').subscribe((translations) => {
      if (translations) {
        const filteredOptions = this.getFilteredOptions();
        this.tabsLabels = filteredOptions.map((sOpt) => ({
          label: this.translate.instant(sOpt.viewValue),
          icon: `<img src="${this.sharedConfig.assetPath}${sOpt.icon}" alt=""/>`,
        }));
      }
    });
  }

  private getFilteredOptions(): SectorTypeDetails[] {
    sectorTypeOptions.find(x => x.value === SectorTypeEnum.DYNAMIC).enabled = this.showDynamicPowerTab || false;
    sectorTypeOptions.find(x => x.value === SectorTypeEnum.HEATPUMP).enabled = !this.hideHeatPump;
    return sectorTypeOptions.filter(x => x.enabled);
  }

  private getCitiesErrorMessage(postCode: string): Observable<{ noCity: string } | null> {

    return this.appService.getCitiesMbs(this.apiUrl, postCode).pipe(
      map((r) => {
        return r.length === 0
          ? {
            noCity: this.getCityResponseMessage[SimplifiedHttpResponse.SUCCESS],
          }
          : null;
      }),
      catchError((e) => {
        return of({
          noCity: this.getCityResponseMessage[e],
        });
      })
    );
  }
}
