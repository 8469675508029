import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppComponent } from './app.component';
import { SharedModule } from '../../../common/src/lib/shared.module';
import { SearchFormModule } from '../../../common/src/lib/components/search-form/search-form.module';
import { LswLoadingbarModule } from '@ui/lsw-lib';
import { HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { API_URL, initializeAppMode } from '../../../settings/app.settings';
import { AppSettingsService } from '../../../settings/app-settings.service';
import { MaintenanceBlockComponent } from '../../../../projects/common/src/lib/components/maintenance-block/maintenance-block.component';
import { RequestInterceptor } from '../../../../projects/common/src/lib/interceptors/request.interceptor';

const apiUrl = document.getElementsByTagName('app-lsw-search')[0].getAttribute('apiUrl');

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserAnimationsModule,
    SharedModule.forRoot({assetPath: document.getElementsByTagName('app-lsw-search')[0].getAttribute('assetPath')}),
    SearchFormModule,
    LswLoadingbarModule,
    MaintenanceBlockComponent,
  ],
  providers: [
    { provide: API_URL, useValue: apiUrl },
    { provide: HTTP_INTERCEPTORS, useClass: RequestInterceptor, multi: true },
    AppSettingsService,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeAppMode,
      multi: true,
      deps: [HttpClient, API_URL],
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
