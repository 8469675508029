import { NgModule } from '@angular/core';

import { EtrackerService } from './_services/etracker.service';

@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [
    EtrackerService
  ]
})
export class EtrackerModule { }
