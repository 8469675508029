import { LswOption, LswTableRowElement } from '@ui/lsw-lib';
import { SapProductsNamesList } from 'projects/products/src/app/product-comparison/product-comparison.constants';
import { MbsProduct } from './mbsProduct';
import * as moment from 'moment';
import { SectorType } from '@ekso/ekso-types';

export interface SearchValues {
  moveInDate: Date;
  customerType: CustomerType;
  sectorType: SectorType;
  consumption: string;
  zipCode: string;
  city: string;
  street: string;
  houseNo?: string;
}

export interface HeatPumpValues {
  firstQuestion: boolean;
  secondQuestion: boolean;
  tariffId: number;
}

export interface DetailValues {
  onlineOption: boolean;
  ecoOption: boolean;
  isLt: boolean;
  ltOption: number;
}

export type CustomerType = 'PRIVATE' | 'BUSINESS';

export type DataSource = 'SAP' | 'MBS';
export type ProductTable = {
  row: LswTableRowElement;
  product: LswTableRowElement;
  base?: LswTableRowElement;
  id?: number;
};

export interface SectorTypeDetails {
  value: SectorType;
  viewValue: string;
  icon: string;
  enabled: boolean;
}

export interface SearchFormAppConfig {
  lang: string;
  customerType: CustomerType;
  sectorType: SectorType;
  showCustomerSelect: boolean;
  showSectorSelect: boolean;
  showConsumptionDropdown: boolean;
  minMoveInDate: Date;
  maxMoveInDate: Date;
  selectedMoveInDate: Date;
  bigConsumptionUrl: string;
  hideHeatPump: boolean;
  showDynamicPowerTab: boolean;
  heatPumpPrivilegeLink: string;
  heatPumpConsumptionLink: string;
}

export interface ProductCharacteristics {
  results: ProductCharacteristic[];
}

export interface ProductCharacteristic {
  Characteristic: string;
  ProductID: string;
  SerialNumber: number;
}

export interface ProductHttp {
  vicinity: boolean;
  GreenText: string;
  Bonus04: false;
  Bonus01Text: string;
  OnlineText: string;
  Bonus04Brutto: string;
  Bonus02Text: string;
  Bonus04Netto: string;
  Bonus03Text: string;
  LvbAccountNum: string;
  Bonus04Text: string;
  BonusID: string;
  BpKindID: string;
  BpKindTxt: string;
  City: string;
  Consumption: string;
  ConsumptionLt: string;
  UseLt: boolean;
  LtP: boolean;
  Description: string;
  DivisionID: string;
  DivisionTxt: string;
  GridID: string;
  HouseNo: string;
  ModelID: string;
  OnlineP: boolean;
  PostCode: string;
  PriceCurrency: string;
  ProductID: SapProductsNamesList;
  ServiceProviderName: string;
  Street: string;
  TariffName: string;
  TariffType: string;
  BasePrice: string;
  BasePriceGross: string;
  BasePriceLt: string;
  BasePriceGrossLt: string;
  Bonus: string;
  Bonus01PriceGross: string;
  Bonus01Price: string;
  Bonus02PriceGross: string;
  Bonus02Price: string;
  Bonus03PriceGross: string;
  Bonus03Price: string;
  GreenPriceGross: string;
  GreenPrice: string;
  MonthlyPrice: string;
  OnlinePPriceGross: string;
  OnlinePPrice: string;
  WorkingNetPrice: string;
  WorkingPriceGross: string;
  WorkingPriceGrossLt: string;
  WorkingPriceLt: string;
  YearlyPrice: string;
  YearlyPriceWithBonus: string;
  GreenPriceGrossFrom: any;
  GreenPriceGrossTo: any;
  GreenPriceFrom: any;
  GreenPriceTo: any;
  OnlinePPriceGrossFrom: any;
  OnlinePPriceGrossTo: any;
  OnlinePPriceFrom: any;
  OnlinePPriceTo: any;
  PriceGuaranteeDate: any;
  PriceGuaranteeDates: any[];
  PriceLevel: string;
  StartDate: string;
  ValidFrom: any;
  Biogas: number;
  PriceGuarantee: number;
  ProductExtension: number;
  Runtime: number;
  BasicSupplyTariff: boolean;
  Bonus01: boolean;
  Bonus02: boolean;
  Bonus03: boolean;
  GreenEnergy: boolean;
  OnlineProduct: boolean;
  ProductSelectable: boolean;
  ProductCharacteristics: ProductCharacteristics;
  ExtraBenefits: {
    results: any[];
  };
  ProductBonus01s: {
    results: any[];
  };
  ProductBonus02s: {
    results: any[];
  };
  ProductBonus03s: {
    results: any[];
  };
  ProductOnlines: {
    results: any[];
  };
  ProductBonus04s: {
    results: any[];
  };
  Tariffs: any;
  Runtimes: {
    results: any[];
  };
  Extensions: {
    results: any[];
  };
  PriceGuarantees: {
    results: any[];
  };
  GreenEnergies: {
    results: any[];
  };
}

export interface Product extends ProductHttp {
  color: string;
  image: string;
  name: string;
  mobileTabName: string;
  description: string;
  buttonText: string;
}

export function instanceOfProduct(object: any): object is Product {
  return (
    'name' in object &&
    'color' in object &&
    'image' in object &&
    'name' in object &&
    'mobileTabName' in object &&
    'description' in object &&
    'buttonText' in object &&
    'GreenEnergy' in object &&
    'Runtime' in object &&
    'PriceGuaranteeDate' in object &&
    'ValidFrom' in object &&
    'PriceLevel' in object &&
    'YearlyPriceWithBonus' in object &&
    'YearlyPrice' in object &&
    'BasePriceGross' in object &&
    'BasePriceGrossLt' in object &&
    'WorkingPriceGross' in object &&
    'WorkingPriceGrossLt' in object &&
    'Bonus' in object &&
    'GreenPriceGross' in object &&
    'OnlinePPriceGross' in object &&
    'Bonus02PriceGross' in object &&
    'Bonus02Text' in object &&
    'Bonus01PriceGross' in object &&
    'Bonus01Text' in object &&
    'MonthlyPrice' in object &&
    'ProductID' in object &&
    'BpKindID' in object &&
    'DivisionID' in object &&
    'Runtimes' in object &&
    'PriceGuarantees' in object &&
    'GreenEnergies' in object &&
    'ServiceProviderName' in object &&
    'StartDate' in object &&
    'ProductCharacteristics' in object &&
    'ProductOnlines' in object
  );
}

export interface StreetsOrProducts {
  basicSupplyProduct: Product;
  streets: LswOption[];
  products: Product[];
}

export enum QUERY_LEVEL {
  BASIC_SEARCH = 0,
  DETAILED_SEARCH,
  SEARCH_DRAIN,
  SEARCH_FAILED,
}

export enum SAP_QUERY_COMPLETENESS {
  BASIC_SEARCH = 0,
  DETAILED_SEARCH,
  SEARCH_FAILED,
}

export enum MBS_QUERY_COMPLETENESS {
  MINIMAL_DATA = 0,
  ADDITIONAL_DATA,
  FULL_DATA,
  SEARCH_FAILED,
}

export interface QueryLevel {
  level: QUERY_LEVEL;
  fail: boolean;
  drain: boolean;
  done: boolean;
  error: any;
}

export interface QueryLevels {
  mbs: QueryLevel;
  sap: QueryLevel;
}

export interface ProductsFetchResult {
  basicSupplyProduct: Product;
  streets: LswOption<string, string>[];
  cities: LswOption<string, string>[];
  products: Product[];
  mbsProducts: MbsProduct[];
  queryLevels: QueryLevels;
}

export interface FootersList<T> {
  nonPlusProductTaxes: T;
  newCustomerBonus: T /* bonusFooterNumber */;
  lowLoad: T /* lowLoadFooterNumber */;
  eco: T /* ecoFooterNumber */;
  bonus: T;
  sofoBonus: T;
}

export const MbsFootersConfig: FootersList<boolean> = {
  nonPlusProductTaxes: false,
  newCustomerBonus: false,
  lowLoad: false,
  eco: false,
  bonus: false,
  sofoBonus: false,
};

export interface TableFooterIndexesConf {
  priceStatusFooterNumber: number;
  bonusFooterNumber: number;
  onlineFooterNumber: number;
  ecoFooterNumber: number;
  lowLoadFooterNumber: number;
  lowLoadFilterNumber: number;
}

export interface RegioDataSetCity {
  CityName: string;
}

export interface RegioDataSetStreet extends RegioDataSetCity {
  Country: string;
  CityCode: string;
  PostCode: string;
  CityName: string;
  StrtCode: string;
  Street: string;
}

export interface ProductSearchSuggestion {
  City: string;
  Street: string;
}

export interface RadioSetValue {
  RadioValue: any;
  RadioName: string;
  RadioDisabled?: boolean;
}

export interface DatePickerConfig {
  disablePicker: boolean;
  initialDate: moment.Moment;
}

export interface MbsProductFootnote {
  index: number;
  textTranslation?: string;
  textTemplate?: string;
  product?: MbsProduct;
  templateData?: any;
}

export interface MbsProductFootnoteList {
  footnotes: MbsProductFootnote[];
  indices: FootersList<number>;
}

export interface RedirectToProductPageResult {
  basicSupplyProduct: Product;
  mbsProducts: MbsProduct[];
  products: Product[];
  queryLevels: QueryLevels;
  shouldRedirect: boolean;
  type: string;
}

export interface FeatureTypeIfc {
  enabled: boolean;
  name: string;
}
