import { Pipe, PipeTransform } from '@angular/core';
import { formatDate, formatNumber } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';

@Pipe({name: 'superscriptedNumber'})
export class SuperscriptedNumber implements PipeTransform {
  transform(value: string): string {
    return value.split(',')[0] + ',<span class=\'decimals\'>' + value.split(',')[1] + '</span>';
  }
}

@Pipe({name: 'formatPrice'})
export class FormatPrice implements PipeTransform {

  constructor(private translateService: TranslateService) {}

  transform(value: any, digitsInfo= '.2-2'): string {
    if (isNaN(Number(value))) {return value; }
    return formatNumber(Number(value), this.translateService.currentLang, digitsInfo);
  }
}

@Pipe({name: 'formatDate'})
export class FormatDate implements PipeTransform {

  constructor(private translateService: TranslateService) {}

  transform(value: string): string {
    return formatDate(value, 'dd.MM.yyyy', this.translateService.currentLang);
  }
}
