import { HttpClient } from "@angular/common/http";
import { AppMode, ContractConclusionSystemFlagsInterfaceDto, Environment } from "@ekso/ekso-types";
import { of } from "rxjs";
import { concatMap } from "rxjs/operators";
import { AppSettingsService } from "./app-settings.service";
import { InjectionToken } from "@angular/core";

export const DynamicUrlMap: {
    [k in Environment]: string;
} = {
    [Environment.DEV]: 'https://guide.l.de/test/pug-dynamic',
    [Environment.PREP]: 'https://guide.l.de/test/pug-dynamic',
    [Environment.PROD]: 'https://www.l.de/stadtwerke/privatkunden/strom/l-strom-dynamisch',
};

export const Ekso2UrlMap: {
    [k in Environment]: string;
} = {
    [Environment.DEV]: 'https://test-kundenportal-stadtwerke.l.de/swlkunden-portal-frontend/',
    [Environment.PREP]: 'https://test-kundenportal-stadtwerke.l.de/swlkunden-portal-frontend/',
    [Environment.PROD]: 'https://kundenportal-stadtwerke.l.de/swlkunden-portal-frontend/',
};

export const maintenanceMessage = 'Wir führen gerade Wartungsarbeiten durch. Daher kannst du leider im Moment nicht alle Funktionen auf unserer Webseite nutzen.';

export const API_URL = new InjectionToken<string>('');

export function initializeAppMode(http: HttpClient, apiUrl: string) {
    return () => http.get<ContractConclusionSystemFlagsInterfaceDto>(`${apiUrl}/feature-flag/system-flags`).pipe(
        concatMap((flags: ContractConclusionSystemFlagsInterfaceDto) => {
            AppSettingsService.appMode = flags.mode;
            AppSettingsService.environment = flags.environment;
            AppSettingsService.setMaintenanceMode(flags.mode === AppMode.MAINTENANCE);
            return of(true);
        })
    );
}
